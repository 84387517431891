// src/components/Form/GoogleButtonNoNotification.js

import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { useUser } from '../../context/UserContext';
import { Button, Typography, Tooltip } from '@mui/material';

const GoogleButtonNoNotification = ({ redirectPath }) => {
    const [loading, setLoading] = useState(false);
    const { setAuthTokens, setCreateVendorFlag } = useUser();
    const navigate = useNavigate();

    const handleGoogleSuccess = async (response) => {
        setLoading(true);
        const code = response.code;

        if (code) {
            try {
                const backendResponse = await api.post('/auth/google/', { code });

                if (backendResponse.status === 200) {
                    const tokens = {
                        access: backendResponse.data.access,
                        refresh: backendResponse.data.refresh
                    };
                    setAuthTokens(tokens);
                    localStorage.setItem('access_token', tokens.access);
                    localStorage.setItem('refresh_token', tokens.refresh);

                    if (redirectPath === '/sign-up') {
                        setCreateVendorFlag();
                    }

                    navigate(redirectPath);
                } else {
                    console.error('Google authentication failed:', backendResponse);
                }
            } catch (error) {
                console.error('Google Sign-Up Error:', error);
            } finally {
                setLoading(false);
            }
        } else {
            console.error('Invalid credential response:', response);
            setLoading(false);
        }
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Sign-Up Error:', error);
    };

    const login = useGoogleLogin({
        onSuccess: handleGoogleSuccess,
        onError: handleGoogleFailure,
        flow: 'auth-code',
        scope: 'openid email profile',
        redirect_uri: window.location.origin,
    });

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <Tooltip title="Sign in with Google">
                <Button
                    variant="outlined"
                    onClick={() => login()}
                    disabled={loading}
                    startIcon={<img src="/assets/Google.svg" alt="Google Icon" style={{ width: '20px' }} />}
                >
                    {loading ? '...' : 'Google'}
                </Button>
            </Tooltip>
            <Typography
                variant="body2"
                component="a"
                href="/sign-in"
                sx={{ color: '#1976d2', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
            >
                or sign in via site
            </Typography>
        </div>
    );
};

export default GoogleButtonNoNotification;
