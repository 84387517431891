import React, {useState, useEffect, useRef} from 'react';
import styled, {keyframes} from 'styled-components';
import InputMask from 'react-input-mask';
import {Button, IconButton, Badge, Tooltip, Alert, AlertTitle, SvgIcon} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LanguageIcon from '@mui/icons-material/Language';
import {
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    LinkedIn as LinkedInIcon,
    Instagram as InstagramIcon,
    YouTube as YouTubeIcon,
    Pinterest as PinterestIcon,
    Reddit as RedditIcon,
    WhatsApp as WhatsAppIcon
} from '@mui/icons-material';

import api from '../../api'; // Используем api для запросов
import GoogleButton from '../Form/GoogleButtonNoNotification';
import {useUser} from '../../context/UserContext';

const TikTokIcon = (props) => (
    <SvgIcon fill="#000000" width="800px" height="800px" viewBox="0 0 512 512" id="icons"
             xmlns="http://www.w3.org/2000/svg">
        <path
            d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/>
    </SvgIcon>
);

const gradientAnimation = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const fadeDownFromButton = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    50% {
        opacity: 0.5;
        transform: translateY(3px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const flipAnimation = keyframes`
    0% {
        transform: rotateY(0);
    }
    50% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0);
    }
`;

const floatAnimation = keyframes`
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-3px);
    }
    100% {
        transform: translateY(0px);
    }
`;

const slideText = keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
`;

const Container = styled.div`
    position: relative;
    display: inline-block;
`;

const AnimatedText = styled.span`
    display: inline-block;
    white-space: nowrap;
`;

const AnimatedButton = styled.button`
    background: linear-gradient(45deg, #2c4157, #5c7cfa, #b3c4db, #eaeef4);
    background-size: 300% 300%;
    color: #1d1d1d;
    font-weight: bold;
    font-size: 16px;
    padding: ${props => props.isLongText ? '1px 20px' : '1px 80px'};
    cursor: pointer;
    text-transform: none;
    animation: ${gradientAnimation} 5s ease infinite;
    transition: box-shadow 0.3s ease;
    display: inline-block;
    min-width: 220px;
    max-width: ${props => props.isLongText ? '200px' : 'auto'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

    &:hover {
        box-shadow: 0 0 10px #5c7cfa;
    }

    &.overflowing:hover ${AnimatedText} {
        animation: ${slideText} 3s linear infinite;
    }

    @media (max-width: 600px) {
        padding: ${props => props.isLongText ? '1px 20px' : '1px 40px'};
        max-width: ${props => props.isLongText ? '150px' : 'auto'};
    }
`;

const GlobalOverride = styled.div`
    @media (max-width: 600px) {
        .my-dropdown {
            max-width: none !important;
        }
    }
`;

const DropDownWrapper = styled.div`
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 999;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    animation: ${fadeDownFromButton} 1.5s ease-in-out forwards;
    transform-origin: top;
    padding: 10px;
    width: 300px;
    display: flex;
    align-items: center;
    gap: 15px;

    @media (max-width: 600px) {
        width: 220px;
    }
`;

const FormContainer = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .auth-section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        button {
            align-self: flex-start;
        }
    }

    .form-section {
        display: flex;
        flex-direction: column;
        gap: 5px;

        textarea,
        input[type="text"] {
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

        textarea.error,
        input[type="text"].error {
            border-color: #d60000;
            background: #fdd;
        }

        .error-message {
            color: #d60000;
            font-size: 12px;
            margin: 0;
            padding: 0;
        }
    }
`;

const LawyerCardWrapper = styled.div`
    perspective: 1000px;
    margin: 5px 0;
    display: flex;
    justify-content: center;

    .card {
        width: 300px;
        height: 200px;
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        transform-style: preserve-3d;
        transition: transform 0.8s;
        position: relative;
        cursor: pointer;

        &.flipping {
            animation: ${flipAnimation} 1s forwards;
        }
    }

    .card-face {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        text-align: center;
        background: #fff;

        font-family: 'Roboto', sans-serif;
        color: #333;

        .title {
            font-weight: bold;
            margin-bottom: 5px;
            font-size: 16px;
            color: #000;
        }

        .desc {
            font-size: 14px;
            color: #666;
            white-space: normal;
            word-wrap: break-word;
            max-width: 90%;
        }
    }

    .card-face.back {
        transform: rotateY(180deg);
    }
`;

const RectangleCardFace = styled.div`
    & img {
        width: 120px;
        height: 80px;
        object-fit: cover;
        margin-bottom: 10px;
        border-radius: 0;
    }
`;

const CircleCardFace = styled.div`
    & img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-bottom: 10px;
        border-radius: 50%;
    }
`;

const AwardCardFace = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #fafafa, #e1e1e1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    & img {
        object-fit: cover;
        ${props => {
            if (props.awardType === "square") {
                return `
                width: 120px;
                height: 120px;
                border-radius: 8px;
            `;
            } else if (props.awardType === "vertical") {
                return `
                width: 100px;
                height: 160px;
                border-radius: 8px;
            `;
            } else if (props.awardType === "horizontal") {
                return `
                width: 160px;
                height: 100px;
                border-radius: 8px;
            `;
            } else {
                return `
                width: 80px;
                height: 80px;
                border-radius: 8px;
            `;
            }
        }}
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        margin-bottom: 10px;
    }

    .desc {
        font-size: 14px;
        color: #666;
        text-align: center;
    }
`;

const SocialCardFace = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    background: #fff;

    .profile-heading {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .main-logo {
        margin-bottom: 20px;
        display: inline-block;
        animation: ${floatAnimation} 3s ease-in-out infinite;
        max-width: 100px;
        max-height: 100px;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            cursor: pointer;
            transition: transform 0.3s ease;
        }

        &:hover img {
            transform: scale(1.05);
        }

        &:active img {
            transform: scale(0.95) rotate(2deg);
        }
    }

    .social-icons {
        display: flex;
        gap: 10px;

        a {
            color: #000;
            text-decoration: none;
            transition: color 0.3s;

            svg {
                font-size: 28px !important;
                width: 28px;
                height: 28px;
            }

            &:hover {
                color: #1976d2;
            }
        }
    }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    & > button {
        flex: 0 0 80%;
    }

    & > *:not(button) {
        flex: 0 0 20%;
        display: flex;
        justify-content: flex-end;
    }
`;

const iconMap = {
    'facebook': <FacebookIcon/>,
    'twitter': <TwitterIcon/>,
    'linkedin': <LinkedInIcon/>,
    'instagram': <InstagramIcon/>,
    'youtube': <YouTubeIcon/>,
    'pinterest': <PinterestIcon/>,
    'reddit': <RedditIcon/>,
    'whatsapp': <WhatsAppIcon/>,
    'tiktok': <TikTokIcon/>
};

function AskMikeMenu({minimizeBanner, minimizeHeader, region}) {
    const {user} = useUser();
    const authorized = !!user;

    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    const [profileError, setProfileError] = useState(null);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [hoverOpen, setHoverOpen] = useState(false);

    useEffect(() => {
        if (authorized) {
            setIsProfileLoading(true);
            setProfileError(null);
            api.get('/profile/')
                .then(response => {
                    if (response.status === 200) {
                        const profileData = response.data;
                        setPhoneNumber(profileData.phone_number || '');
                        setEmailConfirmed(profileData.email_confirmed);
                    } else {
                        setProfileError('Failed to fetch profile data. Please try again.');
                    }
                })
                .catch(() => {
                    setProfileError('An unexpected error occurred while fetching profile data. Please try again.');
                })
                .finally(() => setIsProfileLoading(false));
        }
    }, [authorized]);

    const [configData, setConfigData] = useState(null);
    const [open, setOpen] = useState(false);

    const [question, setQuestion] = useState('');
    const [phone, setPhone] = useState('');
    const [file, setFile] = useState(null);
    const [isFlipping, setIsFlipping] = useState(false);
    const [currentLawyerIndex, setCurrentLawyerIndex] = useState(0);
    const [showInfo, setShowInfo] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);

    const userFullName = user ? `${user.first_name} ${user.last_name}` : 'Guest';
    const isMobile = /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);
    const intervalRef = useRef(null);

    const [questionError, setQuestionError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const [typedMessageLines, setTypedMessageLines] = useState(["", "", ""]);
    const [currentLine, setCurrentLine] = useState(0);
    const [currentChar, setCurrentChar] = useState(0);

    const messageLines = [
        `${userFullName},`,
        "your message has been sent!",
        "Please await a reply shortly."
    ];

    const animatedButtonRef = useRef(null);
    const animatedTextRef = useRef(null);
    const [overflowing, setOverflowing] = useState(false);

    useEffect(() => {
        setPhone(phoneNumber);
    }, [phoneNumber]);

    const flipCard = () => {
        setIsFlipping(true);
        setTimeout(() => {
            setCurrentLawyerIndex((prevIndex) => (prevIndex + 1) % (configData ? 3 : 1));
        }, 500);
        setTimeout(() => {
            setIsFlipping(false);
        }, 1000);
    };

    useEffect(() => {
        const params = region ? {params: {region}} : {};
        api.get('/buttonask/', params)
            .then(response => {
                setConfigData(response.data);
            })
            .catch(() => {
            });
    }, [region]);

    useEffect(() => {
        if (configData) {
            intervalRef.current = setInterval(() => {
                flipCard();
            }, 5000);
        }
        return () => clearInterval(intervalRef.current);
    }, [configData]);

    useEffect(() => {
        let typingInterval = null;
        if (requestSent && currentLine < messageLines.length) {
            typingInterval = setInterval(() => {
                setTypedMessageLines(prev => {
                    const newLines = [...prev];
                    newLines[currentLine] += messageLines[currentLine].charAt(currentChar);
                    return newLines;
                });
                setCurrentChar(currentChar + 1);

                if (currentChar + 1 === messageLines[currentLine].length) {
                    clearInterval(typingInterval);
                    setTimeout(() => {
                        setCurrentLine(currentLine + 1);
                        setCurrentChar(0);
                    }, 500);
                }
            }, 50);
        }

        return () => {
            if (typingInterval) clearInterval(typingInterval);
        };
    }, [requestSent, currentLine, currentChar, messageLines]);

    useEffect(() => {
        if (animatedTextRef.current && animatedButtonRef.current && configData) {
            const buttonLabel = (configData.button_first_word || '') + ' ' + (configData.button_second_word || '');
            const textWidth = animatedTextRef.current.scrollWidth;
            const buttonWidth = animatedButtonRef.current.clientWidth;
            setOverflowing(textWidth > buttonWidth);
        }
    }, [configData]);

    const toggleMenu = () => {
        setOpen(!open);
        minimizeBanner && minimizeBanner();
        minimizeHeader && minimizeHeader();
    };

    const handleCloseDropdown = () => {
        setOpen(false);
        setShowInfo(false);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const validateFields = () => {
        let valid = true;
        if (question.trim().length < 15) {
            setQuestionError('Text must be min 15 chars long');
            valid = false;
        } else if (question.trim().length > 300) {
            setQuestionError('Text must be max 300 chars long');
            valid = false;
        } else {
            setQuestionError('');
        }

        const digits = phone.replace(/\D/g, '');
        if (digits.length < 10) {
            setPhoneError('Full phone required.');
            valid = false;
        } else {
            setPhoneError('');
        }

        return valid;
    };

    const handleSendRequest = async () => {
        if (!authorized) return;
        if (!emailConfirmed) return;
        if (!validateFields()) return;

        setSubmissionError(null);

        const formData = new FormData();
        formData.append('question', question);
        formData.append('phone', phone);
        if (file) {
            formData.append('file', file);
        }

        try {
            const response = await api.post('/ask-mike/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 200) {
                setQuestion('');
                setPhone('');
                setFile(null);
                setRequestSent(true);
            } else {
                setSubmissionError('Failed to send request. Please try again later.');
            }
        } catch (error) {
            setSubmissionError('An error occurred while sending your request. Please try again.');
        }
    };

    const iconColor = file ? "primary" : "default";

    const handleInfoIconClick = () => {
        if (isMobile) {
            setShowInfo((prev) => !prev);
        }
    };

    const handleSocialMouseEnter = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const handleSocialMouseLeave = () => {
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                flipCard();
            }, 5000);
        }
    };

    const renderCardFace = (lawyer) => {
        if (lawyer.type === "rectangle") {
            return (
                <RectangleCardFace className="card-face">
                    <img src={lawyer.img} alt="Lawyer"/>
                    <div className="title">{lawyer.name}</div>
                    <div className="desc">{lawyer.desc}</div>
                </RectangleCardFace>
            );
        } else if (lawyer.type === "circle") {
            return (
                <CircleCardFace className="card-face">
                    <img src={lawyer.img} alt="Lawyer"/>
                    <div className="title">{lawyer.name}</div>
                    <div className="desc">{lawyer.desc}</div>
                </CircleCardFace>
            );
        } else if (lawyer.type === "award") {
            return (
                <AwardCardFace className="card-face" awardType={lawyer.awardType}>
                    <img src={lawyer.img} alt="Award"/>
                    {lawyer.desc && <div className="desc">{lawyer.desc}</div>}
                </AwardCardFace>
            );
        } else if (lawyer.type === "social") {
            return (
                <SocialCardFace
                    className="card-face"
                    onMouseEnter={handleSocialMouseEnter}
                    onMouseLeave={handleSocialMouseLeave}
                >
                    <div className="profile-heading">{lawyer.title || "Profile"}</div>
                    <br/>
                    {lawyer.profileUrl && (
                        <Tooltip title="Click to view your profile">
                            <a href={lawyer.profileUrl} className="main-logo">
                                <img src={lawyer.mainLogo} alt="logo"/>
                            </a>
                        </Tooltip>
                    )}
                    <div className="social-icons">
                        {lawyer.socials.map((social, index) => (
                            <a key={index} href={social.url} target="_blank" rel="noopener noreferrer">
                                {social.icon}
                            </a>
                        ))}
                    </div>
                </SocialCardFace>
            );
        }
    };

    if (!configData || (!configData.button_first_word && !configData.button_second_word)) {
        return null; // Если нет данных, просто не рендерим ничего
    }

    const buttonLabel = (configData.button_first_word || '') + ' ' + (configData.button_second_word || '');
    const isLongText = buttonLabel.length > "Ask Mike".length;

    const slide1Type = configData.slide1_type === 'lawyer' ? 'circle' : 'rectangle';

    const lawyers = [
        {
            img: configData.slide1_img,
            name: configData.slide1_name,
            desc: configData.slide1_desc,
            type: slide1Type
        },
        {
            type: 'award',
            img: configData.slide2_img,
            awardType: configData.slide2_type,
            desc: configData.slide2_desc
        },
        {
            type: 'social',
            mainLogo: configData.slide3_main_logo,
            profileUrl: configData.slide3_profile_url,
            socials: (configData.social_links || []).map(s => {
                const iconKey = s.social_network.name.toLowerCase();
                return {
                    icon: iconMap[iconKey] || <LanguageIcon/>,
                    url: s.url
                };
            }),
            title: configData.slide3_title
        }
    ];

    const currentLawyer = lawyers[currentLawyerIndex];

    return (
        <GlobalOverride>
            <Container>
                <AnimatedButton
                    onClick={toggleMenu}
                    isLongText={isLongText}
                    ref={animatedButtonRef}
                    className={overflowing ? 'overflowing' : ''}
                >
                    <AnimatedText ref={animatedTextRef}>{buttonLabel}</AnimatedText>
                </AnimatedButton>
                {open && (
                    <DropDownWrapper className="my-dropdown">
                        <IconButton
                            sx={{position: 'absolute', top: '5px', right: '5px'}}
                            onClick={handleCloseDropdown}
                        >
                            <CloseIcon/>
                        </IconButton>

                        <Tooltip
                            title={configData?.question_hover_text || "No info"}
                            open={isMobile ? showInfo : undefined}
                            disableHoverListener={isMobile}
                        >
                            <IconButton
                                sx={{position: 'absolute', top: '5px', left: '5px'}}
                                onClick={isMobile ? handleInfoIconClick : undefined}
                            >
                                <HelpOutlineIcon/>
                            </IconButton>
                        </Tooltip>

                        <FormContainer>
                            {!authorized ? (
                                <div className="auth-section">
                                    <p>Please sign in to ask a question</p>
                                    <GoogleButton/>
                                </div>
                            ) : isProfileLoading ? (
                                <p>Loading user profile...</p>
                            ) : profileError ? (
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {profileError}
                                </Alert>
                            ) : !emailConfirmed ? (
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    Your email is not confirmed. Please
                                    <br/>
                                    confirm it before asking a question.
                                </Alert>
                            ) : requestSent ? (
                                <Alert severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    <div style={{whiteSpace: 'pre-line'}}>
                                        {typedMessageLines[0]}
                                        {typedMessageLines[0] && "\n"}
                                        {typedMessageLines[1]}
                                        {typedMessageLines[1] && "\n"}
                                        {typedMessageLines[2]}
                                    </div>
                                </Alert>
                            ) : (
                                <div className="form-section">
                                    <textarea
                                        rows="2"
                                        placeholder="Your question or request..."
                                        value={question}
                                        onChange={(e) => setQuestion(e.target.value)}
                                        className={questionError ? 'error' : ''}
                                    />
                                    {questionError && <p className="error-message">{questionError}</p>}

                                    <InputMask
                                        mask="(999) 999-9999"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    >
                                        {(inputProps) => (
                                            <input
                                                {...inputProps}
                                                type="text"
                                                placeholder="Your phone number..."
                                                className={phoneError ? 'error' : ''}
                                            />
                                        )}
                                    </InputMask>
                                    {phoneError && <p className="error-message">{phoneError}</p>}

                                    {submissionError && (
                                        <Alert severity="error">
                                            <AlertTitle>Error</AlertTitle>
                                            {submissionError}
                                        </Alert>
                                    )}

                                    <Actions>
                                        <Button
                                            variant="outlined"
                                            onClick={handleSendRequest}
                                        >
                                            Send request
                                        </Button>
                                        <Tooltip title="Attach a file here if needed">
                                            <Badge badgeContent={file ? 1 : 0} color="primary">
                                                <IconButton color={iconColor} component="label">
                                                    <UploadFileIcon/>
                                                    <input type="file" hidden onChange={handleFileChange}/>
                                                </IconButton>
                                            </Badge>
                                        </Tooltip>
                                    </Actions>
                                </div>
                            )}

                            <LawyerCardWrapper>
                                <div className={`card ${isFlipping ? 'flipping' : ''}`} onClick={flipCard}>
                                    {currentLawyer && renderCardFace(currentLawyer)}
                                </div>
                            </LawyerCardWrapper>
                        </FormContainer>
                    </DropDownWrapper>
                )}
            </Container>
        </GlobalOverride>
    );
}

export default AskMikeMenu;
