import React, {useContext, useEffect} from 'react';
import CombinedPopularLists from "./CombinedPopularLists";
import {SearchContext} from "../context/SearchContext";
import IndexContentEmpty from "./IndexConent/IndexContentEmpty";
import api from '../api';

const MainContent = ({onShowPopup, region}) => {
    // Берём нужные поля из контекста SearchContext
    const {
        searchSended,
        searchSource,
        setSearchSended,
        currentPage,
        setSearchResults,
        specialization,
        problem,
        cities,
        states,
        zipCode,
        language,
        freeConsultation,
        foundationYears,
        showAdvanced
    } = useContext(SearchContext);

    // Логируем входные данные (region, searchSended и т.п.)


    // Если searchSource не "form", сбрасываем searchSended
    useEffect(() => {
        if (searchSource === 'form') {
        } else {
            if (searchSended) {
                setSearchSended(false);
            }
        }
    }, [searchSource, searchSended, setSearchSended]);

    // Логика, управляющая классом "no-scroll" у <main>
    useEffect(() => {
        const mainElement = document.querySelector('main');

        if (mainElement) {
            if (showAdvanced) {
                mainElement.classList.add('no-scroll');
            } else {
                mainElement.classList.remove('no-scroll');
            }

            return () => {
                if (mainElement) {
                    mainElement.classList.remove('no-scroll');
                }
            };
        }
    }, [showAdvanced]);

    // Обработчик "Показать ещё"
    const handleShowMore = async (page) => {
        try {
            const requestData = {
                specialization,
                problem,
                cities: cities.map(city => city.city_id),
                states: states.map(state => state.state_id),
                zip_code: zipCode,
                language,
                free_consultation: freeConsultation,
                foundation_years: foundationYears,
                page
            };


            const response = await api.post(`/get-filtered-specialization/`, requestData);
            const {results, count, total_pages, current_page} = response.data;


            setSearchResults({results, count, page: current_page});
        } catch (error) {
        }
    };

    return (
        <>
            {!searchSended ? (
                <main className="emptyContent">
                    <IndexContentEmpty/>
                </main>
            ) : (
                <main>
                    <div className="search-result">
                        {/* Передаём region в CombinedPopularLists, чтобы убедиться, что он там используется */}
                        <CombinedPopularLists
                            onShowPopup={onShowPopup}
                            onShowMore={handleShowMore}
                            region={region}
                        />
                    </div>
                </main>
            )}
        </>
    );
};

export default MainContent;
