import React, {useState} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import App from './App';
import StateDetector from './utils/StateDetector'; // путь к вашему компоненту

const AppWrapper = () => {
    const [region, setRegion] = useState(null);

    const handleStateFound = (foundRegion) => {
        setRegion(foundRegion);
    };

    return (
        <>
            {/* StateDetector вызывается только один раз за всю жизнь приложения */}
            <StateDetector onStateFound={handleStateFound}/>
            <Routes>
                <Route path="*" element={<App region={region}/>}/>
            </Routes>
        </>
    );
};

export default AppWrapper;
