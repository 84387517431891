import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import api from '../../api';
import SearchForm from '../SearchForm';
import {SearchContext} from '../../context/SearchContext';
import GeolocationButton from '../GeolocationButton';
import fetchCityByIP from '../FetchCityByIP';
import MultiSelectWithDependentCities from "../MultiSelects/MultiSelectWithDependentCities";
import MultiSelectInColumnWithLabel from "../MultiSelects/MultiSelectInColumnWithLabel";
import MultiSelectFoundationYear from "../MultiSelects/MultiSelectFoundationYear";
import {RiveDemo} from "../../utils/animateExample";
import StarsDemo from "../../utils/animateWhiteStar";
import {TextAnimator} from "../../utils/text-animator";
import SpecializationTabs from "./SpecializationTabs";
import {Button, Menu, Box} from '@mui/material';

// Импортируем новый компонент AskMikeMenu
import AskMikeMenu from './AskMikeMenu';

const HeaderSearch = ({minimizeBanner, minimizeHeader, region}) => {
    const {setSearchResults, changeSearchSended, suggestion, showAdvanced, setShowAdvanced} = useContext(SearchContext);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [defaultCity, setDefaultCity] = useState(null);
    const [defaultCityName, setDefaultCityName] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [isConsultationCheck, setIsConsultationCheck] = useState(false);
    const [selectedFoundationYears, setSelectedFoundationYears] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [minWidth, setMinWidth] = useState(null);
    const textRef = useRef(null);
    const [animator, setAnimator] = useState(null);

    const ALL_SPECIALIZATION = {id: null, name: 'All'};

    const [selectedSpecialization, setSelectedSpecialization] = useState(ALL_SPECIALIZATION);
    const [isAllSpecializationActive, setIsAllSpecializationActive] = useState(true);

    // Состояния для MUI меню (пример из вашего кода)
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMUIButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await api.get('/languages/', {withoutAuth: true});
                const uniqueLanguages = Array.from(new Set(response.data.map(lang => lang.name)))
                    .map(name => response.data.find(lang => lang.name === name));
                setLanguages(uniqueLanguages);
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };
        fetchLanguages();
    }, []);

    useEffect(() => {
        const getCityFromIP = async () => {
            const city = await fetchCityByIP();
            if (city) {
                setDefaultCityName(city);
                setSelectedCity([city]);
            }
        };

        if (!userLocation && !zipCode) {
            getCityFromIP();
        }
    }, [userLocation, zipCode]);

    useEffect(() => {
        if (textRef.current) {
            const animatorInstance = new TextAnimator(textRef.current);
            setAnimator(animatorInstance);
            animatorInstance.animate();

            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    setMinWidth(entry.contentRect.width);
                }
            });

            resizeObserver.observe(textRef.current);

            return () => resizeObserver.disconnect();
        }
    }, [suggestion]);

    const toggleAdvancedSearch = () => {
        setShowAdvanced(!showAdvanced);
        minimizeBanner && minimizeBanner();
        minimizeHeader && minimizeHeader();
    };

    const handleSearch = (data) => {
        setShowNotification(false);

        setTimeout(() => {
            setSearchResults(data);
            changeSearchSended();
            setShowAdvanced(false);
            minimizeBanner && minimizeBanner();
            minimizeHeader && minimizeHeader();
            setShowNotification(true);
        }, 100);
    };

    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    const handleLocationObtained = (zip, location) => {
        setUserLocation(location);
        setZipCode(zip);
        minimizeBanner && minimizeBanner();
        minimizeHeader && minimizeHeader();
    };

    const handleSpecializationClick = (spec) => {
        setSelectedSpecialization(spec);
        setIsAllSpecializationActive(spec.name === 'All');
        minimizeBanner && minimizeBanner();
        minimizeHeader && minimizeHeader();
    };

    const handleSpecializationChange = (spec) => {
        setSelectedSpecialization(spec);
        setIsAllSpecializationActive(spec.name === 'All');
        minimizeBanner && minimizeBanner();
        minimizeHeader && minimizeHeader();
    };

    const handleConsultationClick = useCallback(() => {
        setIsConsultationCheck(!isConsultationCheck);
    }, [isConsultationCheck]);

    const onCityStateChange = (cities, states) => {
        setSelectedCity(cities);
        setSelectedState(states);
    };

    return (
        <div className="header__search" style={{position: 'relative'}}>
            <div className="container">
                <div className="content">
                    {showNotification && (
                        <div className='search__notification' onClick={handleCloseNotification}>
                            <StarsDemo/>
                            <p ref={textRef}>{suggestion}</p>
                            <button>
                                <img src="assets/icon--cross.svg" alt="Close Icon"/>
                            </button>
                        </div>
                    )}
                    <SearchForm
                        onSearch={handleSearch}
                        cities={[...(defaultCity ? [defaultCity] : []), ...selectedCity]}
                        cityName={defaultCityName}
                        states={selectedState}
                        zipCode={zipCode}
                        specialization={selectedSpecialization}
                        onSpecializationChange={handleSpecializationChange}
                        languages={selectedLanguage}
                        isAllSpecializationActive={isAllSpecializationActive}
                        freeConsultation={isConsultationCheck}
                        foundationYears={selectedFoundationYears}
                        minimizeHeader={minimizeHeader}
                        minimizeBanner={minimizeBanner}
                    />
                    <div className="search__filters">
                        <div className="tabs-scroll-wrap">
                            <SpecializationTabs
                                selectedSpecialization={selectedSpecialization}
                                handleSpecializationClick={handleSpecializationClick}
                                isAllSpecializationActive={isAllSpecializationActive}
                            />
                        </div>
                        <div className="search__side">
                            {/* AskMikeMenu */}
                            <AskMikeMenu minimizeBanner={minimizeBanner} minimizeHeader={minimizeHeader}
                                         region={region}/>
                            {/* Advanced Search */}
                            <button
                                className={"search__adv" + (showAdvanced ? ' open' : '')}
                                onClick={toggleAdvancedSearch}
                            >
                                <div className='points'>
                                    <div className='point point1'></div>
                                    <div className='point point2'></div>
                                    <div className='point point3'></div>
                                    <div className='point point4'></div>
                                </div>
                                Advanced Search
                            </button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                            >
                                <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 2, minWidth: '200px'}}>
                                    <img src="assets/some_image.jpg" alt="Картинка" style={{maxWidth: '100%'}}/>
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        handleMenuClose();
                                    }}>
                                        <input type="text" placeholder="Введите данные"
                                               style={{width: '100%', marginBottom: '10px'}}/>
                                        <Button type="submit" variant="outlined">Отправить</Button>
                                    </form>
                                </Box>
                            </Menu>

                            <GeolocationButton onLocationObtained={handleLocationObtained}/>
                        </div>
                    </div>
                    <div className={`search__advanced ${showAdvanced ? 'show' : ''}`}>
                        <div className='grid-container'>
                            <MultiSelectWithDependentCities
                                label="City"
                                onChange={onCityStateChange}
                                states={selectedState}
                                placeholder="Select city"
                            />
                            <MultiSelectInColumnWithLabel
                                label="Language"
                                options={languages.map(lang => ({name: lang.name, code: lang.name}))}
                                onChange={setSelectedLanguage}
                                placeholder=""
                            />
                            <MultiSelectFoundationYear
                                label="Foundation Year"
                                onChange={setSelectedFoundationYears}
                            />
                        </div>
                        <div className='header__search__buttons'>
                            <div className='column desktop'>
                                <label className="label">Consultations</label>
                                <label onClick={handleConsultationClick}
                                       className={isConsultationCheck ? 'consultations-checkbox checked' : 'consultations-checkbox'}>
                                    <span>All</span>
                                    <span>Free</span>
                                </label>
                            </div>
                            <div className='column mobile'>
                                <label className="label">&nbsp;</label>
                                <div className='search__radius'>
                                    <span>Free Consultation</span>
                                    <label
                                        onClick={handleConsultationClick}
                                        className={isConsultationCheck ? 'toggle checked' : 'toggle'}>
                                        <div className='toggle__container'>
                                            <div className='toggle__circle'></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderSearch;
