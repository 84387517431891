import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import api from '../../api';

import InputWithLabel from "../Form/Input/InputWithLabel";
import TextareaWithLabel from "../Form/Input/TextareaWithLabel";
import AddAttorneyPopup from '../popups/AddAttorneysPopup';
// >>> ИМПОРТИРУЕМ ПЕРЕКЛЮЧАТЕЛЬ ТАРИФА <<<
import ToggleBillingPeriod from '../Form/Input/ToggleBillingPeriod';

const CreateLawCompanyProfile = ({ show, onClose, userId, existingProfile }) => {
    const currentYear = new Date().getFullYear();

    // Данные формы (черновик)
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        email: '',
        phone_number: '',
        web_site: '',
        foundation_year: '',
        address: {
            country_id: '1', // по умолчанию (USA)
            state_id: '',
            city_id: '',
            zip_code_id: '',
            street_address: '',
        },
        attorneys: [],
        status: 'inactive', // Начальный статус
    });

    // Переключатель тарифа: 'monthly' или 'annually'
    const [billingPeriod, setBillingPeriod] = useState('monthly');

    // Списки штатов, городов, ZIP
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [zipCodes, setZipCodes] = useState([]);

    // Состояния ошибок, загрузки
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // Список адвокатов + выбранные адвокаты
    const [myAttorneys, setMyAttorneys] = useState([]);
    const [selectedAttorneyIds, setSelectedAttorneyIds] = useState(new Set());

    // Управление «группами» адвокатов (постраничная загрузка на фронте)
    const [visibleGroups, setVisibleGroups] = useState(1);
    const groupArray = (array, groupSize) => {
        const groups = [];
        for (let i = 0; i < array.length; i += groupSize) {
            groups.push(array.slice(i, i + groupSize));
        }
        return groups;
    };
    const groupedAttorneys = groupArray(myAttorneys, 3);

    // Попап для добавления адвоката
    const [isAddAttorneyPopupOpen, setIsAddAttorneyPopupOpen] = useState(false);
    const [currentAttorneyBarNumber, setCurrentAttorneyBarNumber] = useState('');

    // Функция для JWT / CSRF
    const getRequestConfig = useCallback(() => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');
        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };
    }, []);

    // Загрузка штатов
    const fetchStates = useCallback(async (country_id) => {
        try {
            const response = await api.get(
                `/states-filtered/?country_id=${country_id}`,
                getRequestConfig()
            );
            setStates(response.data);
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    }, [getRequestConfig]);

    // Загрузка городов
    const fetchCities = useCallback(async (state_id) => {
        try {
            const response = await api.get(
                `/cities-filtered/?state_id=${state_id}`,
                getRequestConfig()
            );
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    }, [getRequestConfig]);

    // Загрузка zip-кодов
    const fetchZipCodes = useCallback(async (city_id) => {
        try {
            const response = await api.get(
                `/zip-codes/?city_id=${city_id}`,
                getRequestConfig()
            );
            setZipCodes(response.data);
        } catch (error) {
            console.error('Error fetching zip codes:', error);
        }
    }, [getRequestConfig]);

    // При первом рендере — грузим штаты
    useEffect(() => {
        fetchStates(formData.address.country_id); // country_id='1'
    }, [fetchStates, formData.address.country_id]);

    // Если выбрали штат => грузим города
    useEffect(() => {
        if (formData.address.state_id) {
            fetchCities(formData.address.state_id);
        } else {
            setCities([]);
            setFormData(prevData => ({
                ...prevData,
                address: {
                    ...prevData.address,
                    city_id: '',
                    zip_code_id: '',
                    street_address: '',
                },
            }));
        }
    }, [formData.address.state_id, fetchCities]);

    // Если выбрали город => грузим zip
    useEffect(() => {
        if (formData.address.city_id) {
            fetchZipCodes(formData.address.city_id);
        } else {
            setZipCodes([]);
            setFormData(prevData => ({
                ...prevData,
                address: {
                    ...prevData.address,
                    zip_code_id: '',
                    street_address: '',
                },
            }));
        }
    }, [formData.address.city_id, fetchZipCodes]);

    // Если редактируем (existingProfile) => заполняем форму
    useEffect(() => {
        if (existingProfile) {
            const updatedFormData = {
                name: existingProfile.name || '',
                description: existingProfile.description || '',
                email: existingProfile.email || '',
                phone_number: existingProfile.phone_number || '',
                web_site: existingProfile.web_site || '',
                foundation_year: existingProfile.foundation_year || '',
                address: {
                    country_id: existingProfile.address?.country?.country_id
                        ? String(existingProfile.address.country.country_id)
                        : '1',
                    state_id: existingProfile.address?.state?.state_id
                        ? String(existingProfile.address.state.state_id)
                        : '',
                    city_id: existingProfile.address?.city?.city_id
                        ? String(existingProfile.address.city.city_id)
                        : '',
                    zip_code_id: existingProfile.address?.zip_code?.id
                        ? String(existingProfile.address.zip_code.id)
                        : '',
                    street_address: existingProfile.address?.street_address || '',
                },
                attorneys: existingProfile.attorneys || [],
                status: existingProfile.status || 'inactive',
            };

            setFormData(updatedFormData);

            // Выбранные адвокаты
            const attorneyIds = Array.isArray(existingProfile.attorneys)
                ? existingProfile.attorneys.map(a => a.attorney_id)
                : [];
            setSelectedAttorneyIds(new Set(attorneyIds));

            // Подгружаем города / zip
            fetchStates(updatedFormData.address.country_id);
            if (updatedFormData.address.state_id) {
                fetchCities(updatedFormData.address.state_id);
            }
            if (updatedFormData.address.city_id) {
                fetchZipCodes(updatedFormData.address.city_id);
            }
        }
    }, [existingProfile, fetchStates, fetchCities, fetchZipCodes]);

    // Загрузка всех адвокатов
    const fetchAllAttorneys = useCallback(async () => {
        setIsLoading(true);
        let allAttorneys = [];
        let nextPageUrl = `/user-attorneys/?page=1`;

        try {
            while (nextPageUrl) {
                const response = await api.get(nextPageUrl, getRequestConfig());
                const data = response.data;
                allAttorneys = [...allAttorneys, ...data.results];
                nextPageUrl = data.next ? data.next.replace('https://law.law/api', '') : null;
            }
            // Сортируем по ID убыванию
            allAttorneys.sort((a, b) => b.attorney_id - a.attorney_id);
            setMyAttorneys(allAttorneys);
        } catch (error) {
            console.error('Error fetching attorneys:', error);
        } finally {
            setIsLoading(false);
        }
    }, [getRequestConfig]);

    useEffect(() => {
        fetchAllAttorneys();
    }, [fetchAllAttorneys]);

    // Обновление полей формы
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Обновление полей адреса
    const handleAddressChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            address: {
                ...prev.address,
                [name]: value
            }
        }));
    };

    // Чекбоксы адвокатов
    const handleCheckboxChange = (attorneyId) => {
        setSelectedAttorneyIds(prevSelected => {
            const updatedSelected = new Set(prevSelected);
            if (updatedSelected.has(attorneyId)) {
                updatedSelected.delete(attorneyId);
            } else {
                updatedSelected.add(attorneyId);
            }
            return updatedSelected;
        });
    };

    // Валидация
    const validateForm = useCallback(() => {
        const newErrors = {};

        // Company name
        if (!formData.name.trim()) {
            newErrors.name = 'Company name is required.';
        }

        // Email
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required.';
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = 'Invalid email format.';
            }
        }

        // Phone
        if (!formData.phone_number.trim()) {
            newErrors.phone_number = 'Phone number is required.';
        }

        // Foundation year
        const yearValue = String(formData.foundation_year || '').trim();
        if (!yearValue) {
            newErrors.foundation_year = 'Foundation year is required.';
        }

        // Description (>= 120 символов)
        if (formData.description.trim().length < 120) {
            newErrors.description = 'Description must be at least 120 characters.';
        }

        // Website
        const domainContainsDotRegex = /\..+$/;
        if (formData.web_site && !domainContainsDotRegex.test(formData.web_site)) {
            newErrors.web_site = 'Website must contain a dot, like ".com".';
        }

        // Address
        const addressErrors = {};
        if (!formData.address.state_id) {
            addressErrors.state_id = 'State is required.';
        }
        if (!formData.address.city_id) {
            addressErrors.city_id = 'City is required.';
        }
        if (!formData.address.zip_code_id) {
            addressErrors.zip_code_id = 'Zip Code is required.';
        }
        if (!formData.address.street_address.trim()) {
            newErrors.street_address = 'Street and House Number is required.';
        }
        if (Object.keys(addressErrors).length > 0) {
            newErrors.address = addressErrors;
        }

        // Attorneys
        if (selectedAttorneyIds.size === 0) {
            newErrors.attorneys = 'At least one attorney must be selected.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [formData, selectedAttorneyIds]);

    // Сохранение черновика (status='inactive')
    const handleSaveChanges = async () => {
        const draftData = { ...formData, status: 'inactive' };
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', draftData.name);
            formDataToSend.append('description', draftData.description);
            formDataToSend.append('email', draftData.email);
            formDataToSend.append('phone_number', draftData.phone_number);
            formDataToSend.append('web_site', draftData.web_site);
            formDataToSend.append('foundation_year', draftData.foundation_year);
            formDataToSend.append('status', draftData.status);
            formDataToSend.append('user_id', userId);

            // Адрес
            formDataToSend.append('address', JSON.stringify(draftData.address));

            // Адвокаты
            selectedAttorneyIds.forEach(att_id => {
                formDataToSend.append('attorneys', att_id);
            });

            const config = getRequestConfig();
            let response;
            if (existingProfile && existingProfile.id) {
                // Если редактируем существующую
                response = await api.patch(
                    `/legal-companies-draft/${existingProfile.id}/update/`,
                    formDataToSend,
                    config
                );
            } else {
                // Создаём новую
                response = await api.post('/legal-companies/create/', formDataToSend, config);
            }

            if (response.status === 200 || response.status === 201) {
                onClose();
            } else {
                console.error('Error saving draft:', response);
                setErrors({ general: 'Error saving company draft.' });
            }
        } catch (error) {
            console.error('Error saving draft:', error);
            setErrors({ general: 'Unexpected error saving draft.' });
        } finally {
            setIsLoading(false);
        }
    };

    // Отправить на утверждение (status='inactive' + создаём оплату)
    const handleSendToApproval = async () => {
        const approvalData = { ...formData, status: 'inactive' };
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('name', approvalData.name);
        formDataToSend.append('description', approvalData.description);
        formDataToSend.append('email', approvalData.email);
        formDataToSend.append('phone_number', approvalData.phone_number);
        formDataToSend.append('web_site', approvalData.web_site);
        formDataToSend.append('foundation_year', approvalData.foundation_year);
        formDataToSend.append('status', approvalData.status);
        formDataToSend.append('user_id', userId);

        // >>> billing_period (monthly / annually)
        formDataToSend.append('billing_period', billingPeriod);

        formDataToSend.append('address', JSON.stringify(approvalData.address));

        selectedAttorneyIds.forEach(att_id => {
            formDataToSend.append('attorneys', att_id);
        });

        try {
            const config = getRequestConfig();
            let response;
            if (existingProfile && existingProfile.id) {
                response = await api.patch(
                    `/legal-companies-draft/${existingProfile.id}/update/`,
                    formDataToSend,
                    config
                );
            } else {
                response = await api.post('/legal-companies/create/', formDataToSend, config);
            }

            if (response.status === 200 || response.status === 201) {
                // Если бэкенд вернул checkout_sessions => переходим на оплату
                if (
                    response.data &&
                    response.data.checkout_sessions &&
                    response.data.checkout_sessions.length > 0
                ) {
                    const checkoutUrl = response.data.checkout_sessions[0].checkout_url;
                    window.location.href = checkoutUrl;
                } else {
                    // Иначе просто закрываем
                    onClose();
                }
            } else {
                console.error('Error sending for approval:', response);
                setErrors({ general: 'Error sending law firm for approval.' });
            }
        } catch (error) {
            console.error('Error sending approval:', error);
            setErrors({ general: 'Unexpected error sending for approval.' });
        } finally {
            setIsLoading(false);
        }
    };

    // Окно добавления адвоката
    const openAddAttorneyPopup = () => {
        setIsAddAttorneyPopupOpen(true);
    };
    const closeAddAttorneyPopup = () => {
        setIsAddAttorneyPopupOpen(false);
        setCurrentAttorneyBarNumber('');
        fetchAllAttorneys();
    };
    const handleAttorneyAdded = async (newAttorney) => {
        try {
            const config = getRequestConfig();
            const response = await api.post('/attorneys/create/', newAttorney, config);
            if (response.status === 201) {
                const savedAttorney = response.data;
                setFormData(prev => ({
                    ...prev,
                    attorneys: [...prev.attorneys, savedAttorney],
                }));
                setMyAttorneys(prev => [savedAttorney, ...prev]);
            } else {
                console.error('Error adding attorney:', response);
                setErrors({ general: 'Error adding attorney. Please try again.' });
            }
        } catch (error) {
            console.error('Error adding attorney:', error);
            setErrors({ general: 'Unexpected error adding attorney.' });
        }
    };

    // Кнопка "More" для показа дополнительных групп адвокатов
    const handleShowMore = () => {
        setVisibleGroups(prev => prev + 1);
    };

    // Предотвращаем submit на Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    if (!show) return null;

    return ReactDOM.createPortal(
        <>
            {show && (
                <div className="modal visible" id="create-law-company-profile">
                    <div className="modal__overlay" onClick={onClose}></div>
                    <div className="modal__content">
                        <button onClick={onClose} className="button no-style close js--close-modal">
                            <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                        </button>
                        <div className="modal__form">
                            <form
                                className="content"
                                onSubmit={(e) => e.preventDefault()}
                                onKeyDown={handleKeyDown}
                            >
                                <h2>Create Law Firm Profile</h2>
                                {errors.general && (
                                    <div className="error-message">
                                        {errors.general}
                                    </div>
                                )}

                                <InputWithLabel
                                    label="Company Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    error={errors.name}
                                />
                                <TextareaWithLabel
                                    label="Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    error={errors.description}
                                />
                                <InputWithLabel
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    error={errors.email}
                                />
                                <InputWithLabel
                                    label="Phone Number"
                                    name="phone_number"
                                    type="tel"
                                    value={formData.phone_number}
                                    onChange={handleChange}
                                    error={errors.phone_number}
                                />
                                <InputWithLabel
                                    label="Website"
                                    name="web_site"
                                    value={formData.web_site}
                                    onChange={handleChange}
                                    error={errors.web_site}
                                />
                                <InputWithLabel
                                    label="Foundation Year"
                                    name="foundation_year"
                                    type="number"
                                    value={formData.foundation_year}
                                    onChange={handleChange}
                                    min="1800"
                                    max={currentYear}
                                    error={errors.foundation_year}
                                />

                                <span>Address</span>
                                <select
                                    className={`input ${errors.address?.state_id ? 'input-error' : ''}`}
                                    name="state_id"
                                    value={formData.address.state_id}
                                    onChange={(e) => handleAddressChange('state_id', e.target.value)}
                                >
                                    <option value="" disabled>Select State</option>
                                    {states.map((s) => (
                                        <option key={s.state_id} value={s.state_id}>
                                            {s.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.address?.state_id && (
                                    <span className="error-text">{errors.address.state_id}</span>
                                )}

                                {formData.address.state_id && (
                                    <select
                                        className={`input ${errors.address?.city_id ? 'input-error' : ''}`}
                                        name="city_id"
                                        value={formData.address.city_id}
                                        onChange={(e) => handleAddressChange('city_id', e.target.value)}
                                    >
                                        <option value="" disabled>Select City</option>
                                        {cities.map((c) => (
                                            <option key={c.city_id} value={c.city_id}>
                                                {c.name}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {formData.address.state_id && errors.address?.city_id && (
                                    <span className="error-text">{errors.address.city_id}</span>
                                )}

                                {formData.address.city_id && (
                                    <select
                                        className={`input ${errors.address?.zip_code_id ? 'input-error' : ''}`}
                                        name="zip_code_id"
                                        value={formData.address.zip_code_id}
                                        onChange={(e) => handleAddressChange('zip_code_id', e.target.value)}
                                    >
                                        <option value="" disabled>Select Zip Code</option>
                                        {zipCodes.map((z) => (
                                            <option key={z.id} value={z.id}>
                                                {z.zip_code}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {formData.address.city_id && errors.address?.zip_code_id && (
                                    <span className="error-text">{errors.address.zip_code_id}</span>
                                )}

                                {formData.address.zip_code_id && (
                                    <InputWithLabel
                                        label="Street Address"
                                        name="street_address"
                                        value={formData.address.street_address}
                                        onChange={(e) => handleAddressChange('street_address', e.target.value)}
                                        error={errors.street_address}
                                    />
                                )}

                                <label className="input-label input-attorney">
                                    <input
                                        className={`input ${currentAttorneyBarNumber ? 'filled' : ''}`}
                                        type="text"
                                        name="currentAttorneyBarNumber"
                                        value={currentAttorneyBarNumber}
                                        onChange={(e) => setCurrentAttorneyBarNumber(e.target.value)}
                                        placeholder="Attorney License Number"
                                    />
                                    <button
                                        type="button"
                                        className="button button-border"
                                        onClick={openAddAttorneyPopup}
                                    >
                                        Add
                                    </button>
                                </label>

                                {/* Список адвокатов */}
                                <div className="attorneys-list">
                                    <h3>Attorneys List:</h3>
                                    {myAttorneys.length === 0 && !isLoading && (
                                        <p>No attorneys available.</p>
                                    )}
                                    {isLoading && <p>Loading attorneys...</p>}
                                    {!isLoading && myAttorneys.length > 0 && (
                                        <>
                                            {groupedAttorneys.slice(0, visibleGroups).map((group, gIndex) => (
                                                <ul className="attorneys__list" key={`group-${gIndex}`}>
                                                    {group.map((att) => (
                                                        att.attorney_id && (
                                                            <li className="attorney" key={att.attorney_id}>
                                                                <div className="attorney__avatar">
                                                                    <div className="avatar">
                                                                        <picture>
                                                                            <img
                                                                                src={att.photo}
                                                                                alt={`${att.first_name} ${att.last_name}`}
                                                                                className="attorney-photo"
                                                                                loading="lazy"
                                                                            />
                                                                        </picture>
                                                                    </div>
                                                                </div>
                                                                <div className="attorney__content">
                                                                    <div>
                                                                        <h3 className="attorney__name">
                                                                            {att.first_name} {att.last_name}
                                                                        </h3>
                                                                    </div>
                                                                    <ul className="params__list">
                                                                        {att.specializations &&
                                                                            att.specializations.slice(0, 3).map((s) => (
                                                                                <li key={s.specialization_id}>{s.name}</li>
                                                                            ))}
                                                                        {att.specializations && att.specializations.length > 3 && (
                                                                            <li>+{att.specializations.length - 3} more</li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                                <label className="label-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedAttorneyIds.has(att.attorney_id)}
                                                                        onChange={() => handleCheckboxChange(att.attorney_id)}
                                                                    />
                                                                    <div className="psevdocheckbox"></div>
                                                                </label>
                                                            </li>
                                                        )
                                                    ))}
                                                </ul>
                                            ))}
                                            {visibleGroups < groupedAttorneys.length && (
                                                <button
                                                    type="button"
                                                    className="button button-border show-more-button"
                                                    onClick={handleShowMore}
                                                >
                                                    More
                                                </button>
                                            )}
                                        </>
                                    )}
                                    {errors.attorneys && (
                                        <span className="error-message">{errors.attorneys}</span>
                                    )}
                                </div>

                                {/* Тумблер тарифа (monthly / annually) */}
                                <div style={{ margin: '20px 0' }}>
                                    <ToggleBillingPeriod
                                        billingPeriod={billingPeriod}
                                        onBillingPeriodChange={setBillingPeriod}
                                        disabled={false}
                                    />
                                </div>

                                <div className="button-row">
                                    <button
                                        className="button button-border save-draft-button"
                                        type="button"
                                        onClick={handleSaveChanges}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Saving...' : 'Save Draft'}
                                    </button>
                                    <button
                                        className="button button-black create-profile-button"
                                        type="button"
                                        onClick={handleSendToApproval}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Sending...' : 'Send for Approval'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Popup для добавления адвоката */}
            <AddAttorneyPopup
                show={isAddAttorneyPopupOpen}
                onClose={closeAddAttorneyPopup}
                legalCompanyId={existingProfile ? existingProfile.id : null}
                onAttorneyAdded={handleAttorneyAdded}
                initialBarNumber={currentAttorneyBarNumber}
            />
        </>,
        document.body
    );
};

export default CreateLawCompanyProfile;
