import { useState, useEffect } from 'react';

// Глобальные переменные для кэширования результатов вне компонента
let cachedState = null;
let didFetch = false; // Флаг, указывающий, что запрос уже был выполнен

async function fetchStateByIP() {
    if (didFetch) {
        return cachedState;
    }

    didFetch = true;
    try {
        const response = await fetch(`https://api.ipdata.co?api-key=${process.env.REACT_APP_IPDATA_API_KEY}`);
        if (!response.ok) {
            console.error('Non-OK response from IP data API:', response.status, response.statusText);
            return null;
        }

        const data = await response.json();

        if (data && data.region) {
            cachedState = data.region; // Сохраняем в кэш
            return data.region;
        } else {
            console.error('No region in response:', data);
            return null;
        }
    } catch (error) {
        console.error('Error fetching state by IP:', error);
        return null;
    }
}

export default function StateDetector({onStateFound}) {
    const [detectedState, setDetectedState] = useState(cachedState);

    useEffect(() => {
        // Если состояние уже известно (cachedState не null), то просто вызываем колбэк и не делаем запрос
        if (cachedState !== null) {
            if (onStateFound) {
                onStateFound(cachedState);
            }
            return;
        }

        const detectState = async () => {
            const state = await fetchStateByIP();

            if (state !== null) {
                setDetectedState(state);
                if (onStateFound) {
                    onStateFound(state);
                } else {
                }
            } else {
            }
        };

        detectState();
    }, [onStateFound]);

    return null;
}
