// src/context/UserContext.js
import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef
} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import api from '../api';

const UserContext = createContext();

export const UserProvider = ({children}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState(null);
    const [authTokens, setAuthTokens] = useState(() => {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');
        return accessToken && refreshToken ? {access: accessToken, refresh: refreshToken} : null;
    });
    const [loading, setLoading] = useState(true);
    const [attorneyCompanyData, setAttorneyCompanyData] = useState(null);

    const tokensRef = useRef(authTokens);
    useEffect(() => {
        tokensRef.current = authTokens;
    }, [authTokens]);

    const logout = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    }, []);

    const maybeRedirectToSignIn = useCallback(() => {
        // Перенаправляем на /sign-in/, только если в текущем URL есть 'profile'
        if (location.pathname.includes('profile')) {
            navigate('/sign-in/');
        }
    }, [location, navigate]);

    const handleUserFetchError = useCallback((error) => {
        const data = error.response?.data;
        if (data?.code === 'user_not_found' || error.response?.status === 401) {
            console.warn("User not found or unauthorized. Logging out.");
            logout();
            maybeRedirectToSignIn();
        } else {
            console.error("Error fetching user data:", error);
            setUser(null);
        }
    }, [logout, maybeRedirectToSignIn]);

    const refreshTokenFunc = useCallback(async () => {
        const currentRefreshToken = tokensRef.current?.refresh;
        if (!currentRefreshToken) {
            console.error("No refresh token available. Logging out.");
            logout();
            maybeRedirectToSignIn();
            return null;
        }
        try {
            const response = await api.post('/token/refresh/', {
                refresh: currentRefreshToken
            });
            const newAccessToken = response.data.access;
            const newRefreshToken = response.data.refresh || currentRefreshToken;
            const newTokens = {access: newAccessToken, refresh: newRefreshToken};

            setAuthTokens(newTokens);
            localStorage.setItem('access_token', newTokens.access);
            localStorage.setItem('refresh_token', newTokens.refresh);
            return newAccessToken;
        } catch (error) {
            console.error("Failed to refresh token:", error.response?.status, error.response?.data);
            logout();
            maybeRedirectToSignIn();
            return null;
        }
    }, [logout, maybeRedirectToSignIn]);

    const isRefreshing = useRef(false);
    const failedQueue = useRef([]);

    const processQueue = useCallback((error, token = null) => {
        failedQueue.current.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });
        failedQueue.current = [];
    }, []);

    useEffect(() => {
        const requestInterceptor = api.interceptors.request.use(
            config => {
                if (tokensRef.current?.access && !config.withoutAuth) {
                    config.headers['Authorization'] = `Bearer ${tokensRef.current.access}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        const responseInterceptor = api.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;
                // Проверяем статус 401 и что запрос не был повторно отправлен
                if (error.response?.status === 401 && !originalRequest._retry) {
                    if (isRefreshing.current) {
                        // Если токен уже обновляется, ставим запрос в очередь
                        return new Promise((resolve, reject) => {
                            failedQueue.current.push({resolve, reject});
                        }).then(token => {
                            originalRequest.headers['Authorization'] = `Bearer ${token}`;
                            return api(originalRequest);
                        }).catch(err => Promise.reject(err));
                    }

                    originalRequest._retry = true;
                    isRefreshing.current = true;

                    return new Promise(async (resolve, reject) => {
                        try {
                            const newAccessToken = await refreshTokenFunc();
                            if (!newAccessToken) {
                                throw new Error("Unable to refresh token.");
                            }
                            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                            processQueue(null, newAccessToken);
                            resolve(api(originalRequest));
                        } catch (err) {
                            processQueue(err, null);
                            reject(err);
                        } finally {
                            isRefreshing.current = false;
                        }
                    });
                }

                return Promise.reject(error);
            }
        );

        return () => {
            api.interceptors.request.eject(requestInterceptor);
            api.interceptors.response.eject(responseInterceptor);
        };
    }, [refreshTokenFunc, processQueue]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (!authTokens) {
                // Нет токенов — не запрашиваем /user/
                setLoading(false);
                return;
            }

            setLoading(true);
            try {
                const response = await api.get('/user/');
                setUser(response.data);
            } catch (error) {
                if (error.response?.status === 401) {
                    console.warn("Access token expired or user not found, attempting refresh once.");
                    const newAccessToken = await refreshTokenFunc();
                    if (newAccessToken) {
                        try {
                            const response = await api.get('/user/');
                            setUser(response.data);
                        } catch (error2) {
                            console.error("Error after refresh:", error2);
                            handleUserFetchError(error2);
                        }
                    } else {
                        // refreshTokenFunc уже сделал logout()
                    }
                } else {
                    handleUserFetchError(error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [authTokens, refreshTokenFunc, handleUserFetchError]);

    const termsNotAccepted = useMemo(() => {
        return user && user.terms_of_use_accepted === false;
    }, [user]);

    const acceptTermsOfUse = useCallback(async () => {
        if (!user) return;
        try {
            await api.patch('/profile/', {terms_of_use_accepted: true});
            setUser(prev => ({...prev, terms_of_use_accepted: true}));
        } catch (error) {
            console.error("Failed to update terms_of_use_accepted:", error);
        }
    }, [user]);

    const value = useMemo(() => ({
        user,
        setUser,
        authTokens,
        setAuthTokens,
        refreshToken: refreshTokenFunc,
        logout,
        loading,
        isAuthenticated: !!user,
        termsNotAccepted,
        acceptTermsOfUse,
        attorneyCompanyData,
        setAttorneyCompanyData
    }), [
        user,
        authTokens,
        refreshTokenFunc,
        logout,
        loading,
        termsNotAccepted,
        acceptTermsOfUse,
        attorneyCompanyData
    ]);

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
