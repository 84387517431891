// src/components/HeaderBanner.js
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext
} from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../api';
import { SearchContext } from '../../context/SearchContext';

// Хук для контекста
export const useSearchContext = () => {
  return useContext(SearchContext);
};

const HeaderBanner = ({
  isBannerMinimized,
  isPopupActive,
  isPopupAttorneyActive,
  region
}) => {
  const location = useLocation();

  // =========================
  // 1) Стейт баннера
  // =========================
  const [bannerData, setBannerData] = useState({
    eventTitle: '',
    eventDescription: '',
    eventDate: '',
    registrationButtonText: '',
    registrationButtonUrl: '',
    backgroundImage: '',
    eventTitleIcon: '',
    squareImage: '',
    backgroundGradientEnabled: true,
    backgroundGradientColor: 'rgba(44, 65, 87, 0.7)',
  });

  // =========================
  // 2) Свёрнут / развернут
  // =========================
  const [isMinimize, setIsMinimize] = useState(false);

  // =========================
  // 3) Рефы и контекст
  // =========================
  const bannerMin = useRef(null);
  const { setBannerHeight } = useSearchContext();

  // =========================
  // 4) Стейт и переменные для высоты и логики
  // =========================
  const [minBannerStyle, setMinBannerStyle] = useState({
    backgroundImage: '',
    minHeight: '0',
  });
  const [isUserOpened, setIsUserOpened] = useState(false);
  const [isAutoOpened, setIsAutoOpened] = useState(false);
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  // =========================
  // 5) Дополнительные проверки
  // =========================
  const hasCompanyIdOrAttorneyId =
    location.search.includes('companyId') ||
    location.search.includes('attorneyId');
  const isSignInPage = location.pathname.includes('sign-in');
  const isSignUpPage = location.pathname.includes('sign-up');
  const isProfilePage = location.pathname.includes('profile');
  const isForgotPass = window.location.pathname.includes('forgot-password');
  const isResetPass = window.location.pathname.includes('reset-password');

  // =========================
  // 6) Отслеживаем первый рендер
  // =========================
  const isFirstRenderRef = useRef(true);

  // Для плавного появления добавим флаг «fadeIn»
  const [fadeIn, setFadeIn] = useState(false);

  // =========================
  // 7) ResizeObserver
  // =========================
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setBannerHeight(entry.contentRect.height);
        setMinBannerStyle((prev) => ({
          ...prev,
          minHeight: `${entry.contentRect.height}px`,
        }));
      });
    });

    const banner = bannerMin.current;
    if (banner) {
      observer.observe(banner);
    }

    return () => {
      if (banner) {
        observer.unobserve(banner);
      }
      observer.disconnect();
    };
  }, [setBannerHeight]);

  // =========================
  // 8) Следим за пропом isBannerMinimized
  // =========================
  useEffect(() => {
    setIsMinimize(isBannerMinimized);
  }, [isBannerMinimized]);

  // =========================
  // 9) Автооткрытие на некоторых роутингах
  // =========================
  useEffect(() => {
    const currentPath = location.pathname;
    if (
      currentPath.includes('/bio') ||
      currentPath.includes('/law-firm') ||
      currentPath.includes('/vendor') ||
      currentPath === '/'
    ) {
      setIsMinimize(false);
      setIsAutoOpened(true);
    }
  }, [location.pathname]);

  // =========================
  // 10) Лог для region
  // =========================
  useEffect(() => {
    console.log('DEBUG: region prop =', region);
  }, [region]);

  // =========================
  // 11) Запрос данных баннера
  // =========================
  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        // Формируем URL
        const url = region
          ? `/event-banner/?region=${region}`
          : '/event-banner/';
        console.log('DEBUG: Fetching banner from URL =', url);

        const response = await api.get(url, { withoutAuth: true });
        const rData = response.data;

        const registrationUrl =
          rData.registration_button_url?.startsWith('http://') ||
          rData.registration_button_url?.startsWith('https://')
            ? rData.registration_button_url
            : `${process.env.REACT_APP_BASE_URL}${rData.registration_button_url}`;

        setBannerData({
          eventTitle: rData.event_title_text || '',
          eventDescription: rData.event_description_text || '',
          eventDate: rData.event_date_text || '',
          registrationButtonText: rData.registration_button_text || '',
          registrationButtonUrl: registrationUrl,
          backgroundImage: rData.background_image
            ? `${process.env.REACT_APP_BASE_URL}${rData.background_image}`
            : '',
          eventTitleIcon: rData.event_title_icon
            ? `${process.env.REACT_APP_BASE_URL}${rData.event_title_icon}`
            : '',
          squareImage: rData.square_image
            ? `${process.env.REACT_APP_BASE_URL}${rData.square_image}`
            : '',
          backgroundGradientEnabled: rData.background_gradient_enabled,
          backgroundGradientColor: rData.background_gradient_color,
        });

        setMinBannerStyle({
          backgroundImage: rData.background_gradient_enabled
            ? `linear-gradient(${rData.background_gradient_color}, ${rData.background_gradient_color}), url(${process.env.REACT_APP_BASE_URL}${rData.background_image})`
            : `url(${process.env.REACT_APP_BASE_URL}${rData.background_image})`,
          // minHeight вычислим позже через ResizeObserver
          minHeight: bannerMin.current ? `${bannerMin.current.offsetHeight}px` : '0',
        });

        // После получения данных делаем плавное появление
        setFadeIn(true);

      } catch (error) {
        console.error('Error fetching banner data:', error);
      } finally {
        // Убираем флаг первого рендера, если он ещё не убран
        if (isFirstRenderRef.current) {
          isFirstRenderRef.current = false;
        }
      }
    };

    fetchBannerData();
  }, [region]);

  // =========================
  // 12) Обработчик скролла (сворачиваем при скролле, если уже открыты)
  // =========================
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      // Если пользователь или автооткрытие развернули баннер, то при скролле сворачиваем
      if ((isUserOpened || isAutoOpened) && !isMinimize) {
        // Если это первый рендер, возможно, не хотим сразу сворачивать
        if (!isFirstRenderRef.current) {
          setIsMinimize(true);
        }
      }

      setIsScrolledDown(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isUserOpened, isAutoOpened, isMinimize]);

  // =========================
  // 13) Сворачиваем баннер по разным другим условиям — но не на первом рендере
  // =========================
  useEffect(() => {
    if (!isFirstRenderRef.current) {
      if (isPopupActive || isPopupAttorneyActive) {
        setIsMinimize(true);
      }
    }
  }, [isPopupActive, isPopupAttorneyActive]);

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      if (hasCompanyIdOrAttorneyId) {
        setIsMinimize(true);
      }
    }
  }, [hasCompanyIdOrAttorneyId]);

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      if (
        isSignInPage ||
        isProfilePage ||
        isForgotPass ||
        isResetPass ||
        isSignUpPage
      ) {
        setIsMinimize(true);
      }
    }
  }, [isSignInPage, isSignUpPage, isProfilePage, isForgotPass, isResetPass]);

  // =========================
  // 14) Клик для разворачивания
  // =========================
  const handleBannerClick = useCallback(() => {
    if (!isPopupActive && !isPopupAttorneyActive) {
      setIsMinimize(false);
      setIsUserOpened(true);
      setIsAutoOpened(false);
    }
  }, [isPopupActive, isPopupAttorneyActive]);

  // =========================
  // 15) Рендер
  // =========================

  // Допустим, мы хотим применить класс 'fade-in' для плавного появления
  // если fadeIn === true
  const fadeClass = fadeIn ? ' fade-in' : '';

  return (
    <div
      className={
        `header__banner` +
        (isMinimize ? ' min' : '') +
        (isPopupActive || isPopupAttorneyActive ? ' no-pointer' : '') +
        fadeClass
      }
      style={minBannerStyle}
    >
      {/* Минимизированная (верхняя) часть */}
      <div
        className="banner banner-min"
        onClick={!isMinimize || !isScrolledDown ? handleBannerClick : undefined}
        style={{ cursor: isMinimize && isScrolledDown ? 'default' : 'pointer' }}
      >
        <div ref={bannerMin} className="container">
          <div className="content">
            <ul className="banner__list">
              {bannerData.eventTitleIcon && (
                <li className="banner__title">
                  <img src={bannerData.eventTitleIcon} alt="Icon" />
                  <span>{bannerData.eventTitle}</span>
                </li>
              )}
              {bannerData.eventDate && (
                <li className="banner__date">{bannerData.eventDate}</li>
              )}
              {bannerData.registrationButtonUrl &&
                bannerData.registrationButtonText && (
                  <li className="banner__link">
                    <a
                      href={bannerData.registrationButtonUrl}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {bannerData.registrationButtonText} →
                    </a>
                  </li>
                )}
            </ul>
          </div>
        </div>
      </div>

      {/* Расширенная (нижняя) часть */}
      <div className="banner banner-max">
        <button className="button-close" onClick={() => setIsMinimize(true)}>
          <img src="assets/icon--close-CN6QlgAA.svg" alt="Close Icon" />
        </button>
        <div className="container">
          <div className="content">
            <div className="banner-max__logo">
              <picture>
                <source
                  type="image/webp"
                  srcSet={bannerData.squareImage}
                />
                <source
                  type="image/png"
                  srcSet={bannerData.squareImage}
                />
                {bannerData.squareImage && (
                  <img
                    src={bannerData.squareImage}
                    srcSet={bannerData.squareImage}
                    alt="Partner Logo"
                  />
                )}
              </picture>
            </div>
            <div className="banner-max__text">
              <div>
                <div className="banner__title">
                  {bannerData.eventTitleIcon && (
                    <img src={bannerData.eventTitleIcon} alt="Icon" />
                  )}{' '}
                  <span>{bannerData.eventTitle}</span>
                </div>
                <p className="banner__description">
                  {bannerData.eventDescription}
                </p>
              </div>
              <ul className="banner__list">
                {bannerData.eventDate && (
                  <li>
                    <div className="banner__date">{bannerData.eventDate}</div>
                  </li>
                )}
                <li>
                  <a
                    className="banner__link"
                    href={bannerData.registrationButtonUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {bannerData.registrationButtonText}
                    {bannerData.registrationButtonText && ' →'}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
