import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {TextAnimator} from "../../utils/text-animator";
import {RiveDemo} from "../../utils/animateExample";
import api from '../../api';

const HeaderDescription = () => {
    const [animator, setAnimator] = useState(null);
    const [staticText, setStaticText] = useState('');
    const [mobileText, setMobileText] = useState('');
    const [linkName, setLinkName] = useState(''); // New state for link name
    const [linkUrl, setLinkUrl] = useState('');   // New state for link URL
    const [hoverDescription, setHoverDescription] = useState(false);
    const descriptionRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        api.get('/first-home-page-description/', {withoutAuth: true})
            .then(response => {
                setStaticText(response.data.static_text);
                setMobileText(response.data.mobile_text);
                setLinkName(response.data.link_name); // Set link name
                setLinkUrl(response.data.link_url);   // Set link URL
            })
            .catch(error => {
                console.error('Error fetching description:', error);
            });
    }, []);

    useEffect(() => {
        if (descriptionRef.current) {
            const animatorInstance = new TextAnimator(descriptionRef.current);
            setAnimator(animatorInstance);
        }
    }, [mobileText]);

    const animateText = useCallback(() => {
        if (animator) {
            animator.reset();
            animator.animate();
        }
    }, [animator]);

    const handleIntersection = useCallback((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                animateText();
            }
        });
    }, [animateText]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });
        if (descriptionRef.current) {
            observer.observe(descriptionRef.current);
        }
        return () => {
            if (descriptionRef.current) {
                observer.unobserve(descriptionRef.current);
            }
        };
    }, [handleIntersection]);

    const handlerHover = useCallback(() => {
        setHoverDescription(true);
        if (!hoverDescription) {
            animateText();
        }
    }, [hoverDescription, animateText]);

    // Close description on route change
    useEffect(() => {
        setHoverDescription(false);
    }, [location.pathname]);

    return (
        <div className="header__description">
            <div className="container">
                <div className='content'>
                    <div className="content-left">
                        <p>{staticText}</p>
                        {/* Render dynamic link */}
                        <a href={linkUrl}>{linkName}</a>
                    </div>
                    <div className="content-right">
                        <div className='icons'>
                            <RiveDemo/>
                        </div>
                        <p
                            onMouseOver={handlerHover}
                            onMouseLeave={() => setHoverDescription(false)}
                            ref={descriptionRef}
                        >
                            {mobileText}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderDescription;
