// SpecializationTabs.jsx
import React, {useState, useEffect} from 'react';
import api from '../../api';
import {RiveDemo} from "../../utils/animateExample";

const SpecializationTabs = ({
                                selectedSpecialization,
                                handleSpecializationClick,
                                isAllSpecializationActive,
                            }) => {
    const [specializations, setSpecializations] = useState([]);

    useEffect(() => {
        const fetchSpecializations = async () => {
            try {
                const response = await api.get('/specializations/display/', {withoutAuth: true});
                setSpecializations(response.data);
            } catch (error) {
                console.error('Error fetching specializations:', error);
            }
        };

        fetchSpecializations();
    }, []);

    return (
        <ul className="search__tabs js--search-tab">
            <li
                key="all"
                className={isAllSpecializationActive ? 'active' : ''}
                onClick={() => handleSpecializationClick({id: null, name: 'All'})}
                data-text="All text"
            >
                <RiveDemo/>
            </li>
            {specializations.map((spec) => (
                <li
                    key={spec.specialization_id}
                    className={selectedSpecialization.id === spec.specialization_id ? 'active' : ''}
                    onClick={() => handleSpecializationClick({id: spec.specialization_id, name: spec.name})}
                    data-text={`${spec.name} text`}
                >
                    {spec.name}
                </li>
            ))}
        </ul>
    );
};

export default SpecializationTabs;

