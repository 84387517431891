import React, {useState, useEffect} from 'react';
import {MultiSelect} from 'primereact/multiselect';
import api from '../../api';
import './style.css';

const MultiSelectWithDependentCities = ({label, onChange, disabled, isZipcodeProvided}) => { // Принимаем проп disabled
    const [states, setStates] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await api.get('/states/', {withoutAuth: true});
                setStates(response.data);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates();
    }, []);

    useEffect(() => {
        const fetchCities = async () => {
            if (selectedStates.length === 0) {
                setCities([]);
                return;
            }

            try {
                const stateIds = selectedStates.map(state => state.state_id).join(',');
                const response = await api.get(`/cities-for-multiselect/?states=${stateIds}`, {withoutAuth: true});
                setCities(response.data);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities();
    }, [selectedStates]);

    useEffect(() => {
        const selectedStateIds = selectedStates.map(state => state.state_id);
        const filteredSelectedCities = selectedCities.filter(city =>
            cities.some(c => c.city_id === city.city_id && selectedStateIds.includes(c.state_id))
        );
        setSelectedCities(filteredSelectedCities);
    }, [selectedStates, cities]);

    useEffect(() => {
        if (onChange) {
            onChange(selectedCities, selectedStates);
        }
    }, [selectedCities, selectedStates, onChange]);

    return (
        <>
            <div className="column">
                <label className="label">States</label>
                <MultiSelect
                    value={selectedStates}
                    options={states}
                    onChange={(e) => setSelectedStates(e.value)}
                    optionLabel="name"
                    display="chip"
                    filter
                    className=""
                    placeholder={isZipcodeProvided ? "Near Me Location Detected" : "Select states"}
                    disabled={disabled} // Используем проп disabled для отключения
                />
            </div>
            <div className="column">
                <label className="label">Cities</label>
                <MultiSelect
                    value={selectedCities}
                    options={cities}
                    onChange={(e) => setSelectedCities(e.value)}
                    optionLabel="name"
                    display="chip"
                    filter
                    className=""
                    placeholder="Select states at the beginning"
                    disabled={disabled || selectedStates.length === 0} // Отключаем, если disabled true или нет выбранных штатов
                />
            </div>
        </>
    );
};

export default MultiSelectWithDependentCities;
