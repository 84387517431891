import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useUser} from '../../context/UserContext';

const HeaderMenu = ({minimizeStep, showFeedbackPopup, showSharePopup}) => {
    const [openMobileMenu, setOpenMobileMenu] = React.useState('');
    const location = useLocation();
    const menuRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const {user} = useUser();

    const toggleMenu = useCallback(() => {
        setOpenMobileMenu((prevState) => (prevState.length === 0 ? 'open' : ''));
    }, []);

    const handleLinkClick = useCallback(() => {
        setOpenMobileMenu('');
    }, []);

    const handleClickOutside = useCallback((event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setOpenMobileMenu('');
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const userIconSrc = location.pathname === '/profile'
        ? (isMobile ? "/assets/icon--user-colored.svg" : "/assets/icon--user-white.svg")
        : "/assets/icon--user-Cgmbq9yL.svg";

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    // Добавляем слушатель скролла для автозакрытия меню
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                // Если пользователь прокрутил вниз страницу, закрываем меню
                setOpenMobileMenu('');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`header__menu step-${minimizeStep} ${openMobileMenu}`} ref={menuRef}>
            <div className="container justify">
                <div className="left">
                    <button onClick={toggleMenu} className="hamburger"><span></span></button>
                    <div className="mobile-menu-wrap">
                        <ul className="menu">
                            <li><Link to="/" className={location.pathname === '/' ? 'active' : ''}
                                      onClick={handleLinkClick}>Home</Link></li>
                            <li><Link to="/law-firms" className={location.pathname === '/law-firms' ? 'active' : ''}
                                      onClick={handleLinkClick}>Law Firms</Link></li>
                            <li><Link to="/vendors" className={location.pathname === '/vendors' ? 'active' : ''}
                                      onClick={handleLinkClick}>Vendors</Link></li>
                        </ul>
                    </div>
                    <ul className="menu user mobile">
                        <li><a href="" onClick={(e) => {
                            showFeedbackPopup(e);
                            handleLinkClick();
                        }}>Assist</a></li>
                    </ul>
                </div>
                <div className="logo">
                    <div className="logo__img"><img src="/assets/logo--img-Bjfxg2Ti.svg" alt="logo image"/></div>
                    <Link to="/" onClick={handleLinkClick}>
                        <div className="logo__text"><img src="/assets/logo--text-BszNfwNg.svg" alt="logo text"/></div>
                    </Link>
                </div>
                <ul className="menu user">
                    <li className='desktop'><a href="" onClick={(e) => {
                        showFeedbackPopup(e);
                        handleLinkClick();
                    }}>Assist</a></li>
                    <li><Link to="/bio" className={location.pathname === '/bio' ? 'active' : ''}
                              onClick={handleLinkClick}>Bio</Link></li>
                    <li>
                        {user ? (
                            <li>
                                <Link to="/profile" className={location.pathname === '/profile' ? 'active' : ''}
                                      onClick={handleLinkClick}>
                                    <img src={userIconSrc} alt="user icon"/>
                                    <span>{`${user.first_name} ${user.last_name}`}</span>
                                </Link>
                            </li>
                        ) : (
                            <>
                                <li>
                                    <Link to="/sign-in" className={location.pathname === '/sign-in' ? 'active' : ''}
                                          onClick={handleLinkClick}>Sign In</Link>
                                </li>
                            </>
                        )}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default HeaderMenu;
