import './indexContent.css';
import '../Footer/Footer.css';
import {RiveDemo} from "../../utils/animateExample";
import React, {useCallback, useEffect, useRef, useState} from "react";
import api from '../../api';
import {TextAnimator} from "../../utils/text-animator";
import Footer from "../Footer/Footer";

export default function IndexContentEmpty() {
    const [animator, setAnimator] = useState(null);
    const [hoverDescription, setHoverDescription] = useState(false);
    const [listTitle, setListTitle] = useState('');
    const [listDescription, setListDescription] = useState('');
    const titleRef = useRef(null);

    useEffect(() => {
        api.get('/first-home-page-description/', {withoutAuth: true})
            .then(response => {
                setListTitle(response.data.list_title);
                setListDescription(response.data.list_description);
            })
            .catch(error => {
                console.error('Error fetching description:', error);
            });
    }, []);

    useEffect(() => {
        if (listTitle && titleRef.current) {
            const animatorInstance = new TextAnimator(titleRef.current);
            setAnimator(animatorInstance);
        }
    }, [listTitle]);


    const animateText = useCallback(() => {
        if (animator) {
            animator.reset();
            animator.animate();
        }
    }, [animator]);

    const handleIntersection = useCallback((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                animateText();
            }
        });
    }, [animateText]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });
        if (titleRef.current) {
            observer.observe(titleRef.current);
        }
        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, [handleIntersection]);

    const handlerHover = useCallback(() => {
        setHoverDescription(true);
        if (!hoverDescription) {
            animateText();
        }
    }, [hoverDescription, animateText]);

    return (
        <div className='content'>
            {/*<div className='icons'>*/}
            {/*    <RiveDemo/>*/}
            {/*</div>*/}
            {/*<p*/}
            {/*    onMouseOver={handlerHover}*/}
            {/*    onMouseLeave={() => setHoverDescription(false)}*/}
            {/*    className='title centered-left-span'*/}
            {/*    ref={titleRef}*/}
            {/*>*/}
            {/*    {listTitle || 'AI search continuously helps find accurate answers.'}*/}
            {/*</p>*/}
            {/*<p className='description'>*/}
            {/*    {listDescription || 'AI search continuously helps find accurate answers'}*/}
            {/*</p>*/}
            <footer className='footer'>
                <div className='footer__bottom'>
                    <img src='/assets/logo--footer.svg' className='footer__logo' alt='Logo'/>
                    <span className='footer__copyright'>
                                    &copy;&nbsp;AI Revolution LLC, 2025 . All rights reserved
                                </span>

                    <nav className='footer__nav'>
                        <ul>
                            <li>
                                <a href='/privacy-policy'>Privacy Policy</a>
                            </li>
                            <li>
                                <a href='/cookie-policy'>Cookie Policy</a>
                            </li>
                            <li>
                                <a href='/terms-of-use'>Terms Of Use</a>
                            </li>
                            <li>
                                <a href='/attorney-adv-rules'>Attorney Advertising Rules</a>
                            </li>
                            <li>
                                <a href='/dmca-policy'>DMCA Policy</a>
                            </li>

                            <li>
                                <a href="//www.dmca.com/Protection/Status.aspx?ID=cafa144c-7515-4779-9564-f2b74029a9fa"
                                   title="DMCA.com Protection Status" className="dmca-badge"> <img
                                    src="https://images.dmca.com/Badges/dmca-badge-w150-5x1-01.png?ID=cafa144c-7515-4779-9564-f2b74029a9fa"
                                    alt="DMCA.com Protection Status"/></a>
                                <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
                            </li>
                        </ul>
                    </nav>
                </div>
            </footer>

        </div>
    );
}
