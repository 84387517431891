import React, {useState, useRef, useEffect} from 'react';
import api from '../../api';
import {Box, Typography, Card, CardContent} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const SignUpVideo = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [posterUrl, setPosterUrl] = useState(null);
    const [contents, setContents] = useState([]); // здесь будем хранить наши 3 контента

    const videoRef = useRef(null);

    useEffect(() => {
        const fetchVideoData = async () => {
            try {
                const response = await api.get('/signupvideo-data/');
                if (response.data) {
                    setVideoUrl(response.data.videoUrl);
                    setPosterUrl(response.data.posterUrl);

                    // Формируем массив из трех карточек с содержимым
                    const fetchedContents = [
                        response.data.content1,
                        response.data.content2,
                        response.data.content3
                    ].filter(Boolean); // убираем пустые или null значения

                    setContents(fetchedContents.slice(0, 3));
                }
            } catch (error) {
                console.error('Error fetching video data:', error);
            }
        };

        fetchVideoData();
    }, []);

    const handlePlayButtonClick = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    const handleVideoPlay = () => {
        setIsPlaying(true);
    };

    const handleVideoPause = () => {
        setIsPlaying(false);
    };

    return (
        <Box>
            {/* Отображаем ровно три карточки с данными из API */}
            <Box
                sx={{
                    mt: 1,
                    mb: 1.5,
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2,
                    flexWrap: 'wrap',
                    '& > div': {
                        flex: {xs: '1 0 100%', md: '1 0 auto'},
                        maxWidth: {xs: '300px', md: '200px'},
                    },
                }}
            >
                {contents.map((text, i) => (
                    <Card
                        key={i}
                        sx={(theme) => ({
                            textAlign: 'center',
                            py: 1,
                            px: 1,
                            border: '1px solid #b3c4db',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            borderRadius: 3,
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            // Ховер эффект только на десктопных разрешениях
                            [theme.breakpoints.up('md')]: {
                                '&:hover': {
                                    transform: 'translateY(-8px)',
                                    boxShadow: '0 10px 20px rgba(0,0,0,0.15)'
                                }
                            }
                        })}
                    >
                        <CardContent sx={{pt: 0}}>
                            <Typography
                                sx={{
                                    fontWeight: 'medium',
                                    color: '#333',
                                    fontSize: '1.4rem',
                                    textAlign: 'center',
                                    overflow: 'hidden',
                                    lineHeight: 1.5,
                                    display: '-webkit-box',
                                    WebkitLineClamp: 5,
                                    WebkitBoxOrient: 'vertical',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {text}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>

            {/* Видео */}
            <Box sx={{
                position: 'relative',
                backgroundColor: '#f5f5f5',
                mb: 0,
                borderRadius: 6,
                overflow: 'hidden'
            }}>
                {videoUrl ? (
                    <Box
                        component="video"
                        width="100%"
                        ref={videoRef}
                        onPlay={handleVideoPlay}
                        onPause={handleVideoPause}
                        controls
                        poster={posterUrl || undefined}
                    >
                        <source src={videoUrl} type="video/mp4"/>
                        Ваш браузер не поддерживает видео-тег.
                    </Box>
                ) : (
                    <Typography variant="body2">Loading data...</Typography>
                )}
                {!isPlaying && videoUrl && (
                    <Box
                        onClick={handlePlayButtonClick}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '48px',
                            color: '#fff',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 64,
                            height: 64,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            borderRadius: '50%'
                        }}
                    >
                        <PlayArrowIcon fontSize="large"/>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default SignUpVideo;
