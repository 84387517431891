import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import InputWithLabel from "../Form/Input/InputWithLabel";
import api from '../../api';
import { useNotification } from '../../context/NotificationContext';
import { useUser } from '../../context/UserContext'
import './SignIn.css';
import Loader from "../Loader";
import GoogleButton from '../Form/GoogleButton';
import SignUpVideo from './SignUpVideo'; // Импортируем видеокомпонент, если хотите тот же использовать
// import getLogoUrl from '../../utils/getLogoUrl'; // Если видео заменит изображение, этот импорт может не понадобиться

const SignIn = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const { setAuthTokens } = useUser();

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            navigate('/profile');
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setLoading(true);

        if (!formData.email || !formData.password) {
            const newErrors = {
                email: !formData.email ? 'Email is required.' : '',
                password: !formData.password ? 'Password is required.' : ''
            };
            setErrors(newErrors);

            showNotification(
                'Please fill in all required fields.',
                'Make sure to check the correctness of the entered data.',
                null,
                'error'
            );

            setLoading(false);
            return;
        }

        try {
            const response = await api.post('/token/', {
                username: formData.email,
                password: formData.password
            });

            if (response.status === 200) {
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);

                showNotification(
                    'Login successful.',
                    'Welcome back!',
                    null,
                    'success'
                );

                setAuthTokens({
                    access: response.data.access,
                    refresh: response.data.refresh
                });

                navigate('/profile');
            } else {
                setErrors({
                    email: response.data.detail || 'Invalid credentials.',
                    password: ' '
                });

                showNotification(
                    'Invalid credentials. Please try again.',
                    'Check the entered information and try again.',
                    null,
                    'error'
                );
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors({
                    email: error.response.data.detail || 'Something went wrong. Please try again later.',
                    password: ' '
                });

                showNotification(
                    error.response.data.detail || 'Login error. Please try again later.',
                    'Check your entered information or try again later.',
                    null,
                    'error'
                );
            } else {
                setErrors({ email: 'Something went wrong. Please try again later.' });

                showNotification(
                    'Network error. Please check your connection and try again.',
                    'Ensure you have an active internet connection and try again.',
                    null,
                    'error'
                );
            }
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loader />
    }

    return (
        <div className="main-form-container">
            {/* Заменяем блок с изображением на компонент с видео, как в SignUp */}
            <SignUpVideo />

            <div className="main-form__right">
                <form className="main-form__content" onSubmit={handleSubmit}>
                    <div className="scroll-wrap">
                        <h2>Let's get started</h2>
                        <div className="sign-up-link">
                            <span>Don't have an account? </span>
                            <Link to="/sign-up">Sign Up</Link>
                        </div>

                        <InputWithLabel
                            label='E-mail'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            type='email'
                            hasError={!!errors.email}
                        />
                        <InputWithLabel
                            label='Password'
                            name='password'
                            value={formData.password}
                            onChange={handleChange}
                            type='password'
                            hasError={!!errors.password}
                        />
                        <div
                            className="forgot-password-link"
                            style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '15px' }}
                        >
                            <Link to="/forgot-password">Forgot Password?</Link>
                        </div>

                        <button className="button button-black" type="submit" disabled={loading}>
                            {loading ? 'Loading...' : 'Continue'}
                        </button>

                        <GoogleButton redirectPath="/profile" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
