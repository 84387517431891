import React, { useEffect, useState, useCallback } from 'react';
import api from '../../../api';

/**
 * Компонент-«переключатель» тарифа (Monthly / Annually).
 *
 * @param {string}   billingPeriod        - Текущее значение тарифа: 'monthly' или 'annually'.
 * @param {Function} onBillingPeriodChange - Колбэк, вызываемый при смене тарифа.
 * @param {Object}   company             - Данные о компании (если надо подтягивать её план).
 * @param {boolean}  disabled            - Флаг, запрещающий менять тариф (по умолчанию false).
 */
export default function ToggleBillingPeriod({
  billingPeriod = 'monthly',
  onBillingPeriodChange,
  company = null,
  disabled = false,
}) {
  const [planData, setPlanData] = useState(null);

  // Получаем данные плана (если нужно показывать цены)
  useEffect(() => {
    const fetchPlanData = async () => {
      try {
        if (company && company.active_plans && company.active_plans.length > 0) {
          const activeProPlan = company.active_plans.find(
            (planObj) =>
              planObj.plan &&
              planObj.plan.name === 'Pro' &&
              planObj.subscription_status === 'active'
          );

          if (activeProPlan && activeProPlan.plan) {
            setPlanData({
              billing_period: activeProPlan.billing_period || 'monthly',
              monthly_price: activeProPlan.plan.monthly_price,
              annual_price: activeProPlan.plan.annual_price,
            });
          } else {
            // Если нет активного плана Pro, подгружаем данные Pro плана с бэка
            const response = await api.get('/plans/pro/');
            setPlanData({
              monthly_price: response.data.monthly_price,
              annual_price: response.data.annual_price,
            });
          }
        } else {
          // Если company не выбрана или нет active_plans, просто берём базовые данные Pro
          const response = await api.get('/plans/pro/');
          setPlanData({
            monthly_price: response.data.monthly_price,
            annual_price: response.data.annual_price,
          });
        }
      } catch (error) {
        console.error('Ошибка при получении плана Pro:', error);
        setPlanData(null);
      }
    };

    fetchPlanData();
  }, [company]);

  // Клик по переключателю (monthly ↔ annually)
  const handleBillingToggle = useCallback(() => {
    if (!disabled) {
      const newBilling = billingPeriod === 'monthly' ? 'annually' : 'monthly';
      // Уведомляем родительский компонент о смене тарифа
      if (onBillingPeriodChange) {
        onBillingPeriodChange(newBilling);
      }
    }
  }, [disabled, billingPeriod, onBillingPeriodChange]);

  // Обработка клавиш Enter / Space, чтобы переключатель был доступен для клавиатуры
  const handleKeyPress = useCallback(
    (e) => {
      if (!disabled && (e.key === 'Enter' || e.key === ' ')) {
        handleBillingToggle();
      }
    },
    [disabled, handleBillingToggle]
  );

  return (
    <div className="billing-toggle-container">
      <div className="billing-toggle">
        <span
          className={`billing-text ${
            billingPeriod === 'monthly' ? 'active' : 'inactive'
          }`}
        >
          Monthly
        </span>

        <div
          className={`toggle ${billingPeriod === 'annually' ? 'checked' : ''}`}
          onClick={!disabled ? handleBillingToggle : undefined}
          role="switch"
          aria-checked={billingPeriod === 'annually'}
          tabIndex={disabled ? -1 : 0}
          onKeyPress={handleKeyPress}
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.5 : 1,
          }}
        >
          <div className="toggle__container">
            <div className="toggle__circle" />
          </div>
        </div>

        <span
          className={`billing-text ${
            billingPeriod === 'annually' ? 'active' : 'inactive'
          }`}
        >
          Annually
        </span>
      </div>

      {/* Блок с ценами, если нужен */}
      {planData && (
        <div className="billing-prices">
          {billingPeriod === 'monthly' ? (
            <span className="billing-price active">
              ${planData.monthly_price}/month
            </span>
          ) : (
            <>
              {/* Неактивная "ежемесячная" цена, если хотите её отображать серым */}
              <span className="billing-price inactive">
                ${planData.monthly_price}
              </span>
              {/* Активная "годовая" цена, пересчитанная в месяцы */}
              <span className="billing-price active">
                ${(planData.annual_price / 12).toFixed(2)}/month
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
}
