import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import api from '../../api';
import externalApi from '../../api/externalApi';
import {useNotification} from '../../context/NotificationContext';
import {useUser} from '../../context/UserContext';
import Cookies from 'js-cookie';
import InputWithLabel from '../Form/Input/InputWithLabel';
import MultiSelectInColumnWithLabelExtension from '../../components/MultiSelects/MultiSelectInColumnWithLabelExtension';
import SignUpVideo from './SignUpVideo'; // Компонент с видео
import Loader from '../Loader';

// <<< Импортируем переключатель тарифа (monthly/annually) >>>
import ToggleBillingPeriod from '../../components/Form/Input/ToggleBillingPeriod';

const VerifyNewPromoEmailToken = () => {
    const navigate = useNavigate();
    const {showNotification} = useNotification();
    const {token} = useParams();
    const {user, setAuthTokens} = useUser();

    const [loading, setLoading] = useState(true);
    const [attorneyData, setAttorneyData] = useState(null);
    const [activated, setActivated] = useState(false);
    const [showCreatePopup, setShowCreatePopup] = useState(false);

    // Данные формы
    const currentYear = new Date().getFullYear();
    const [formData, setFormData] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        licenseYear: '',
        specializations: [],
        registrationNumber: '',
        phoneNumber: '',
        address: {
            country_id: '1',
            state_id: '',
            city_id: '',
            zip_code_id: '',
            street_address: ''
        }
    });
    const [errors, setErrors] = useState({});
    const [allSpecializations, setAllSpecializations] = useState([]);

    // Тариф: "monthly" или "yearly"
    const [billingPeriod, setBillingPeriod] = useState('monthly');

    // Согласие с условиями
    const [agree, setAgree] = useState(false);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [zipCodes, setZipCodes] = useState([]);

    // Флаги для автозаполнения адреса
    const [addressProcessed, setAddressProcessed] = useState(false);
    const [addressData, setAddressData] = useState(null);
    const [needToSetCity, setNeedToSetCity] = useState(false);
    const [needToSetZip, setNeedToSetZip] = useState(false);
    const [needToSetStreet, setNeedToSetStreet] = useState(false);

    const getRequestConfig = useCallback(() => {
        const tokenLocal = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');
        return {
            headers: {
                Authorization: `Bearer ${tokenLocal}`,
                'X-CSRFToken': csrfToken
            },
            withCredentials: true
        };
    }, []);

    // Получаем список штатов
    const fetchStates = useCallback(
        async (country_id) => {
            try {
                const response = await api.get(
                    `/states-filtered/?country_id=${country_id}`,
                    getRequestConfig()
                );
                setStates(response.data);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        },
        [getRequestConfig]
    );

    // Получаем список городов на основе state_id
    const fetchCities = useCallback(
        async (state_id) => {
            try {
                const response = await api.get(
                    `/cities-filtered/?state_id=${state_id}`,
                    getRequestConfig()
                );
                setCities(response.data);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        },
        [getRequestConfig]
    );

    // Получаем список ZIP-кодов на основе city_id
    const fetchZipCodes = useCallback(
        async (city_id) => {
            try {
                const response = await api.get(
                    `/zip-codes/?city_id=${city_id}`,
                    getRequestConfig()
                );
                setZipCodes(response.data);
            } catch (error) {
                console.error('Error fetching zip codes:', error);
            }
        },
        [getRequestConfig]
    );

    // При монтировании — загружаем штаты (country_id=1)
    useEffect(() => {
        fetchStates('1');
    }, [fetchStates]);

    // Подгружаем города при изменении state_id
    useEffect(() => {
        if (formData.address.state_id) {
            fetchCities(formData.address.state_id);
        } else {
            setCities([]);
            setFormData((prev) => ({
                ...prev,
                address: {
                    ...prev.address,
                    city_id: '',
                    zip_code_id: '',
                    street_address: ''
                }
            }));
        }
    }, [formData.address.state_id, fetchCities]);

    // Подгружаем ZIP при изменении city_id
    useEffect(() => {
        if (formData.address.city_id) {
            fetchZipCodes(formData.address.city_id);
        } else {
            setZipCodes([]);
            setFormData((prev) => ({
                ...prev,
                address: {
                    ...prev.address,
                    zip_code_id: '',
                    street_address: ''
                }
            }));
        }
    }, [formData.address.city_id, fetchZipCodes]);

    // Загружаем все специализации (справочник)
    useEffect(() => {
        const fetchAllSpecializations = async () => {
            try {
                const response = await api.get('/specializations-full/');
                setAllSpecializations(response.data);
            } catch (error) {
                console.error('Error fetching all specializations:', error);
            }
        };
        fetchAllSpecializations();
    }, []);

    // Обработчик изменений полей формы
    const handleChange = useCallback((e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }, []);

    // Обновление адреса
    const handleAddressChange = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            address: {
                ...prev.address,
                [name]: value
            }
        }));
    };

    // Обработчик выбора специализаций
    const handleSpecializationChange = (selectedSpecs) => {
        const codes = selectedSpecs.map((spec) => spec.specialization_id);
        setFormData((prevData) => ({
            ...prevData,
            specializations: codes
        }));
    };

    // Проверка промо-токена и получение данных адвоката (через externalApi)
    useEffect(() => {
        const fetchAttorneyData = async () => {
            try {
                const response = await externalApi.get(
                    `/promo-attorney-detail/${token}/`
                );
                if (response.status >= 200 && response.status < 300) {
                    setAttorneyData(response.data);
                } else {
                    showNotification(
                        'Error',
                        'Unable to retrieve lawyer details.',
                        null,
                        'error'
                    );
                }
            } catch (error) {
                console.error('Error fetching attorney data:', error);
                showNotification(
                    'Error',
                    "There was an error retrieving the attorney's details.",
                    null,
                    'error'
                );
            } finally {
                setLoading(false);
            }
        };

        // Дополнительная проверка токена
        const checkTokenValidity = async () => {
            if (!token || token.trim() === '') {
                // Если токен пуст — всё равно пытаемся получить attorneyData
                fetchAttorneyData();
                return;
            }

            try {
                const checkResponse = await api.get(
                    `/check-promo-token/${token}/`
                );
                if (checkResponse.status >= 200 && checkResponse.status < 300) {
                    if (checkResponse.data.valid) {
                        // Токен валидный — возможно, уже активирован — тогда переходим на логин
                        showNotification(
                            'Token Found',
                            'The token is valid. Redirecting to sign-in.',
                            null,
                            'info'
                        );
                        navigate('/sign-in/');
                    } else {
                        // Если checkResponse.data.valid === false, всё равно пробуем fetchAttorneyData
                        fetchAttorneyData();
                    }
                } else {
                    fetchAttorneyData();
                }
            } catch (error) {
                console.error('Error checking token validity:', error);
                fetchAttorneyData();
            }
        };

        checkTokenValidity();
    }, [showNotification, token, navigate]);

    // Если attorneyData загружены, токен не пуст и ещё не активировано — показываем форму
    useEffect(() => {
        if (!loading && attorneyData && token && token.trim() !== '' && !activated) {
            setActivated(true);
            setShowCreatePopup(true);
        } else if (!loading && (!attorneyData || !token)) {
            // Если attorneyData нет или нет токена — уводим на /sign-up/
            navigate('/sign-up/');
        }
    }, [loading, attorneyData, token, navigate, activated]);

    // Автозаполнение адреса при наличии attorneyData.address
    useEffect(() => {
        const processAttorneyAddress = async () => {
            if (
                attorneyData &&
                attorneyData.address &&
                attorneyData.address.trim() !== '' &&
                !addressProcessed
            ) {
                try {
                    const response = await api.post(
                        '/process-address/',
                        {address: attorneyData.address},
                        getRequestConfig()
                    );
                    if (response.status >= 200 && response.status < 300) {
                        const addrData = response.data;
                        setAddressData(addrData);

                        // Заполняем форму данными об адвокате
                        setFormData((prev) => ({
                            ...prev,
                            firstName: attorneyData.first_name || '',
                            lastName: attorneyData.last_name || '',
                            email: attorneyData.email || '',
                            phoneNumber: attorneyData.phone || '',
                            licenseYear: attorneyData.admission_date
                                ? attorneyData.admission_date.split('-')[0]
                                : '',
                            registrationNumber: attorneyData.bar_code
                                ? attorneyData.bar_code.toString()
                                : '',
                            companyName: attorneyData.full_name || ''
                        }));

                        // Находим state_id по имени штата из addrData
                        const foundState = states.find(
                            (st) =>
                                st.name.toLowerCase().trim() ===
                                addrData.state?.toLowerCase().trim()
                        );
                        const stateId = foundState ? foundState.state_id : '';
                        if (stateId) {
                            setFormData((prev) => ({
                                ...prev,
                                address: {
                                    ...prev.address,
                                    state_id: stateId
                                }
                            }));
                            setNeedToSetCity(true);
                        }
                    }
                } catch (error) {
                    console.error('Error processing address:', error);
                } finally {
                    setAddressProcessed(true);
                }
            }
        };
        if (states.length > 0) {
            processAttorneyAddress();
        }
    }, [attorneyData, addressProcessed, states, getRequestConfig]);

    // Когда задали state_id, пытаемся подставить city_id
    useEffect(() => {
        if (needToSetCity && addressData && cities.length > 0) {
            const foundCity = cities.find(
                (ct) =>
                    ct.name.toLowerCase().trim() ===
                    addressData.city?.toLowerCase().trim()
            );
            const cityId = foundCity ? foundCity.city_id : '';
            if (cityId) {
                setFormData((prev) => ({
                    ...prev,
                    address: {
                        ...prev.address,
                        city_id: cityId
                    }
                }));
                setNeedToSetCity(false);
                setNeedToSetZip(true);
            }
        }
    }, [needToSetCity, addressData, cities]);

    // Когда задали city_id, пытаемся подставить zip_code_id
    useEffect(() => {
        if (needToSetZip && addressData && zipCodes.length > 0) {
            const foundZip = zipCodes.find(
                (z) => z.zip_code === addressData.zip_code
            );
            const zipId = foundZip ? foundZip.id : '';
            if (zipId) {
                setFormData((prev) => ({
                    ...prev,
                    address: {
                        ...prev.address,
                        zip_code_id: zipId
                    }
                }));
                setNeedToSetZip(false);
                setNeedToSetStreet(true);
            }
        }
    }, [needToSetZip, addressData, zipCodes]);

    // Подставляем улицу
    useEffect(() => {
        if (needToSetStreet && addressData) {
            setFormData((prev) => ({
                ...prev,
                address: {
                    ...prev.address,
                    street_address: addressData.street_address || ''
                }
            }));
            setNeedToSetStreet(false);
        }
    }, [needToSetStreet, addressData]);

    // Если есть practice_areas у attorneyData — подставляем подходящие specializations
    useEffect(() => {
        if (
            attorneyData &&
            allSpecializations.length > 0 &&
            attorneyData.practice_areas &&
            attorneyData.practice_areas.length > 0
        ) {
            const matchedSpecs = allSpecializations.filter((spec) => {
                const paClean = attorneyData.practice_areas.map((pa) =>
                    pa.toLowerCase().trim()
                );
                return paClean.includes(spec.name.toLowerCase().trim());
            });
            if (matchedSpecs.length > 0) {
                const codes = matchedSpecs.map(
                    (spec) => spec.specialization_id
                );
                setFormData((prevData) => ({
                    ...prevData,
                    specializations: codes
                }));
            }
        }
    }, [attorneyData, allSpecializations]);

    // Валидация формы
    const validateForm = useCallback(() => {
        const newErrors = {};
        if (!formData.companyName.trim()) {
            newErrors.companyName = 'Company name is required';
        }
        if (!formData.firstName.trim()) {
            newErrors.firstName = 'First name is required';
        }
        if (!formData.lastName.trim()) {
            newErrors.lastName = 'Last name is required';
        }
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = 'Invalid email format';
            }
        }
        if (!formData.phoneNumber.trim()) {
            newErrors.phoneNumber = 'Phone number is required';
        } else {
            const phoneRegex = /^\+?[0-9\s\-()]{7,20}$/;
            if (!phoneRegex.test(formData.phoneNumber)) {
                newErrors.phoneNumber = 'Invalid phone number format';
            }
        }
        if (!formData.licenseYear) {
            newErrors.licenseYear = 'License year is required';
        } else {
            const year = Number(formData.licenseYear);
            if (isNaN(year) || year < 1900 || year > currentYear) {
                newErrors.licenseYear = `License year must be between 1900 and ${currentYear}`;
            }
        }
        if (!formData.registrationNumber.trim()) {
            newErrors.registrationNumber = 'Registration number is required';
        }
        if (
            !formData.specializations ||
            formData.specializations.length === 0
        ) {
            newErrors.specializations =
                'At least one specialization must be selected';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [formData, currentYear]);

    // Сабмит (создание)
    const handleCreate = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.companyName);
        formDataToSend.append('attorney_first_name', formData.firstName);
        formDataToSend.append('attorney_last_name', formData.lastName);
        formDataToSend.append('attorney_email', formData.email);
        formDataToSend.append('attorney_phone', formData.phoneNumber);
        formDataToSend.append('attorney_license_year', formData.licenseYear);
        formDataToSend.append('attorney_registration_number', formData.registrationNumber);
        formDataToSend.append('user_email', formData.email);
        formDataToSend.append('user_first_name', formData.firstName);
        formDataToSend.append('user_last_name', formData.lastName);
        formDataToSend.append('user_password', 'someSecurePassword');
        formDataToSend.append('promo_token', token);

        // Добавляем выбранный billing_period
        formDataToSend.append('billing_period', billingPeriod);

        formData.specializations.forEach((specId) => {
            formDataToSend.append('attorney_specializations', specId);
        });

        const addressPayload = {
            country_id: formData.address.country_id,
            state_id: formData.address.state_id,
            city_id: formData.address.city_id,
            zip_code_id: formData.address.zip_code_id,
            street_address: formData.address.street_address
        };
        formDataToSend.append('address', JSON.stringify(addressPayload));

        // Прочие поля
        formDataToSend.append('foundation_year', '2020');
        formDataToSend.append(
            'description',
            'This is a sample law firm description that is longer than 120 characters. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt.'
        );
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone_number', formData.phoneNumber);
        formDataToSend.append('status', 'inactive');
        formDataToSend.append('source', 'CreateLawFirmQuick');

        try {
            const config = getRequestConfig();
            const response = await api.post('/new-activate-promo-create-user/', formDataToSend, config);

            if (response.status === 201 || response.status === 200) {
                const data = response.data;
                // Если сервер вернул токены
                if (data.access && data.refresh) {
                    localStorage.setItem('authToken', data.access);
                    localStorage.setItem('refreshToken', data.refresh);

                    setAuthTokens({
                        access: data.access,
                        refresh: data.refresh
                    });
                }
                // <<< ВАЖНО: Проверяем, есть ли checkout_sessions в ответе >>>
                if (data.checkout_sessions && data.checkout_sessions.length > 0) {
                    // Берём первую сессию (или как вам нужно)
                    const checkoutUrl = data.checkout_sessions[0].checkout_url;
                    // Перенаправляем пользователя на Stripe
                    window.location.href = checkoutUrl;
                } else {
                    // Если нет checkout_sessions — просто закрываем попап и переходим в профиль
                    onClosePopup();
                }
            } else {
                setErrors({
                    general: 'Error creating attorney and company. Please try again.'
                });
            }
        } catch (error) {
            console.error(
                'Error adding attorney and company:',
                error.response ? error.response.data : error.message
            );
            setErrors({
                general: 'Error creating attorney and company. Please try again.'
            });
        }
    };

    const onClosePopup = () => {
        setShowCreatePopup(false);
        navigate('/profile/');
    };

    const selectedSpecs = allSpecializations.filter((spec) =>
        formData.specializations.includes(spec.specialization_id)
    );

    if (loading) {
        return <Loader/>;
    }

    return (
        <div className="main-form-container">
            <SignUpVideo />
            <div className="main-form__right">
                {showCreatePopup && (
                    <form className="main-form__content" onSubmit={handleCreate}>
                        <div className="scroll-wrap">
                            <h2>Create Company and Attorney</h2>
                            {errors.general && (
                                <div className="error-message">{errors.general}</div>
                            )}

                            <InputWithLabel
                                name="companyName"
                                label="Company Name"
                                value={formData.companyName}
                                onChange={handleChange}
                                error={errors.companyName}
                            />
                            <InputWithLabel
                                name="firstName"
                                label="First Name"
                                value={formData.firstName}
                                onChange={handleChange}
                                error={errors.firstName}
                            />
                            <InputWithLabel
                                name="lastName"
                                label="Last Name"
                                value={formData.lastName}
                                onChange={handleChange}
                                error={errors.lastName}
                            />
                            <InputWithLabel
                                name="phoneNumber"
                                label="Phone Number"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                error={errors.phoneNumber}
                            />
                            <InputWithLabel
                                name="email"
                                label="Email"
                                value={formData.email}
                                onChange={handleChange}
                                error={errors.email}
                            />
                            <InputWithLabel
                                name="licenseYear"
                                label="License Year"
                                value={formData.licenseYear}
                                onChange={handleChange}
                                type="number"
                                min="1900"
                                max={currentYear}
                                error={errors.licenseYear}
                            />
                            <InputWithLabel
                                name="registrationNumber"
                                label="Registration Number"
                                value={formData.registrationNumber}
                                onChange={handleChange}
                                error={errors.registrationNumber}
                            />

                            <div className="form-group">
                                <label className="form-label">Specializations</label>
                                <MultiSelectInColumnWithLabelExtension
                                    label=""
                                    options={allSpecializations}
                                    onChange={handleSpecializationChange}
                                    placeholder="Select specializations"
                                    initialValue={selectedSpecs}
                                    maxSelectedLabels={3}
                                />
                                {errors.specializations && (
                                    <span className="error-message">
                                        {errors.specializations}
                                    </span>
                                )}
                            </div>

                            <label className="form-label">Address</label>
                            <select
                                className="input"
                                name="state_id"
                                value={formData.address.state_id}
                                onChange={(e) =>
                                    handleAddressChange('state_id', e.target.value)
                                }
                            >
                                <option value="" disabled>
                                    Select State
                                </option>
                                {states.map((state) => (
                                    <option key={state.state_id} value={state.state_id}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>

                            {formData.address.state_id && (
                                <select
                                    className="input"
                                    name="city_id"
                                    value={formData.address.city_id}
                                    onChange={(e) =>
                                        handleAddressChange('city_id', e.target.value)
                                    }
                                >
                                    <option value="" disabled>
                                        Select City
                                    </option>
                                    {cities.map((city) => (
                                        <option key={city.city_id} value={city.city_id}>
                                            {city.name}
                                        </option>
                                    ))}
                                </select>
                            )}

                            {formData.address.city_id && (
                                <select
                                    className="input"
                                    name="zip_code_id"
                                    value={formData.address.zip_code_id}
                                    onChange={(e) =>
                                        handleAddressChange('zip_code_id', e.target.value)
                                    }
                                >
                                    <option value="" disabled>
                                        Select Zip Code
                                    </option>
                                    {zipCodes.map((zip) => (
                                        <option key={zip.id} value={zip.id}>
                                            {zip.zip_code}
                                        </option>
                                    ))}
                                </select>
                            )}

                            {formData.address.zip_code_id && (
                                <InputWithLabel
                                    name="street_address"
                                    label="Street Address"
                                    value={formData.address.street_address}
                                    onChange={(e) =>
                                        handleAddressChange('street_address', e.target.value)
                                    }
                                />
                            )}

                            {/* Выбор тарифа через ToggleBillingPeriod */}
                            <ToggleBillingPeriod
                                billingPeriod={billingPeriod}
                                onBillingPeriodChange={setBillingPeriod}
                                disabled={false}
                            />

                            <div className="checkbox-container" style={{marginTop: '20px'}}>
                                <label className="label-checkbox">
                                    <input
                                        type="checkbox"
                                        name="agreeToTerms"
                                        checked={agree}
                                        onChange={(e) => setAgree(e.target.checked)}
                                    />
                                    <div className="psevdocheckbox"></div>
                                </label>
                                <span className="text-left">
                                    I agree to the{' '}
                                    <a
                                        href="/terms-of-use"
                                        className="checkbox__link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="/privacy-policy"
                                        className="checkbox__link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>.
                                </span>
                            </div>

                            <button
                                type="submit"
                                className="button button-black"
                                disabled={!agree}
                            >
                                Create
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default VerifyNewPromoEmailToken;
