const fetchCityByIP = async () => {
    try {
        const response = await fetch(`https://api.ipdata.co?api-key=${process.env.REACT_APP_IPDATA_API_KEY}`);

        if (!response.ok) {
            console.error("Non-OK response from ipdata:", response.status, response.statusText);
            return null;
        }

        const data = await response.json();

        if (data && data.city) {
            return data.city;
        } else {
            console.error('Error fetching city by IP - city not found in data:', data);
            return null;
        }
    } catch (error) {
        console.error('Error fetching city by IP:', error);
        return null;
    }
};

export default fetchCityByIP;
